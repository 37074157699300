/* eslint-disable */
import React from 'react';
import CustomSlider from './slider'
import './main.css';
import arow from '../automobile/arow.png'
import uparow from '../automobile/uparow.png'

export default class SecoudMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showColor: true,
            showTexture: false,
        }
    }
    componentDidMount() {
        console.log("this.props",this.props)
    }

    colorChange = (hexColor) => {
        this.props.colorChange(hexColor)
    }

    imageChange = (obj) => {
        this.props.imageChange(obj)
    }
    roughnessChange = (newValue) => {
        this.props.roughnessChange(newValue)
    }

    metalNessChange = (newValue) => {
        this.props.metalNessChange(newValue)
    }

    isVisibleChange = () => {
        this.props.isVisibleChange()
    }

    colorShow = () => {
        this.setState({ showColor: !this.state.showColor })
    }

    render() {

        return (

            <div className="positionexteriorbtn lefttoright" id='openexterior'>

                <ul className="exteriorflex" id="exteriorList">
                    <li>
                <div className="meshItem">{this.props.meshName}</div>
                </li>
                    <li>
                        <button className="commonbtn marignrightzero" id="projectname1" onClick={() => {
                            this.setState({
                                showColor: !this.state.showColor, showTexture: false,
                                showMetalness: false,
                                showRoughness: false
                            })
                        }}> Color </button>
                    </li>
                    <li>
                        <button className="commonbtn marignrightzero" id="projectname1" onClick={() => {
                            this.setState({
                                showTexture: !this.state.showTexture, showColor: false,
                                showMetalness: false,
                                showRoughness: false
                            })
                        }}> Texture </button>
                    </li>

                    <li>
                        <button className="commonbtn marignrightzero" id="projectname1" onClick={() => {
                            this.setState({
                                showTexture: false,
                                showColor: false,
                                showMetalness: !this.state.showMetalness,
                                showRoughness: false
                            })
                        }}> Metal ness </button>
                    </li>
                    <li>
                        <button className="commonbtn marignrightzero" id="projectname1" onClick={() => {
                            this.setState({
                                showTexture: false,
                                showColor: false,
                                showMetalness: false,
                                showRoughness: !this.state.showRoughness
                            })
                        }}> Rough ness </button>
                    </li>


                </ul>


                <ul className="exteriorflex borderleft">
                    {this.state.showColor && this.props.colores.map((obj) => {
                        return (
                            <li>
                                <div style={{ backgroundColor: obj, borderRadius: '5px', height: '30', width: '30' }} onClick={() => this.colorChange(obj)}>
                                    <p style={{ height: 40, width: 40 }}></p>
                                </div>
                            </li>

                        )
                    })}

                    {this.state.showTexture && this.props.images.map((obj) => {
                        return (
                            <li onClick={() => this.imageChange(obj)}>
                                <img src={'https://d2mjqh1irji0ds.cloudfront.net/' + obj} width="40" height="40" style={{ borderRadius: '5px' }} />
                            </li>

                        )
                    })}

                    {this.state.showMetalness &&
                        <li>
                            <CustomSlider sliderType="metalNess" metalNessChange={this.metalNessChange} max={1} min={0.01} step={0.01} defaultValue={0.1} />
                        </li>
                    }

                    {this.state.showRoughness &&
                        <li>
                            <CustomSlider sliderType="roughness" roughnessChange={this.roughnessChange} max={1} min={0.01} step={0.01} defaultValue={0.1} />
                        </li>
                    }

                </ul>
            </div>


            // <div className="panelrightposition" style={{ marginLeft: '15px' }} id="secondaryMenu">
            //     <div className=" secondary marginrightrotatepanel">
            //         <div className="row changeSceneName firstRow">
            //             <input id="sceneNameInput" className="marginRightFive" value={this.props.partName}></input>
            //         </div>

            //         <div className="row changeSceneName firstRow">
            //             <p className="col-xs-5 optionName marginRightFive margleft ">Visible</p>
            //             <input id="chkisAmbientLight" type="checkbox" className="col-xs-10 marginRightFive" checked={this.props.isVisible} onChange={this.isVisibleChange}></input>
            //         </div>

            //         {/* {this.props.isMetal &&
            //             <div className="row changeSceneName firstRow">
            //                 isMetal
            //             </div>
            //         }

            //         {this.props.isDiamond &&
            //             <div className="row changeSceneName firstRow">
            //                 isDiamond
            //             </div>
            //         }

            //         {this.props.isStone &&
            //             <div className="row changeSceneName firstRow">
            //                 isStone
            //             </div>
            //         } */}






            //         <div className="row changeSceneName firstRow">
            //             <input id="sceneNameInput" className="marginRightFive" value="Select Color"></input>
            //         </div>

            //         {this.props.colores.map((obj) => {
            //             return (
            //                 <div className="row changeZDepth " style={{ backgroundColor: obj }} onClick={() => this.colorChange(obj)}>
            //                     <div>{obj}</div>
            //                 </div>
            //             )
            //         })}

            //         <div className="row changeSceneName firstRow">
            //             <input id="sceneNameInput" className="marginRightFive" value="Select Image"></input>
            //         </div>
            //         {this.props.images.map((obj) => {
            //             return (
            //                 <div className="row changeZDepth " onClick={() => this.imageChange(obj)}>
            //                     <img src={obj} className='loadImage' alt="Smiley face" width="25" height="25" //>
            //                 </div>
            //             )
            //         })}

            //     </div>
            // </div>
        );
    }
}


