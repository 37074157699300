/* eslint-disable */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Input from '@material-ui/core/Input';

const PrettoSlider = withStyles({
  root: {
    color: '#7fae41',
    height: 6,
  },
  thumb: {
    height: 15,
    width: 15,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -5,
    marginLeft: -12,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-100% + 2px)',
  },
  track: {
    height: 6,
    borderRadius: 3,
  },
  rail: {
    height: 6,
    borderRadius: 3,
  },
})(Slider);

export default function CustomSlider(props) {
  const [value, setValue] = React.useState(0);
  const [once, setOnce] = React.useState(false);

  const handleSliderChange = (event, newValue) => {
    updateSelectedEntity(newValue);
    setValue(newValue);
  };

  const handleInputChange = event => {
    const newValue = event.target.value === '' ? '' : Number(event.target.value);
    updateSelectedEntity(newValue);
    setValue(event.target.value === '' ? '' : Number(event.target.value));
  };
  const updateSelectedEntity = (newValue) => {
    const widgetContainerEl = document.querySelector("#widget-container");
    if (props.sliderType === "roughness") {
      props.roughnessChange(newValue)
    }
    if (props.sliderType === "metalNess") {
      props.metalNessChange(newValue)
    }
  };
  if (!once) {
    setValue(props.value);
    setOnce(true);
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginRight: '5px', alignItems: 'center' }}>
      <Input
        className="borderinput"
        value={value}
        onChange={handleInputChange}
        name="depthValue"
        inputProps={{
          step: props.step,
          min: props.min,
          max: props.max,
          type: 'number',
          'aria-labelledby': 'input-slider',
        }}
        style={{marginRight:'10px'}}
      />
      <PrettoSlider
        valueLabelDisplay="auto"
        aria-label="pretto slider"
        value={value}
        onChange={handleSliderChange}
        defaultValue={props.defaultValue}
        max={props.max}
        min={props.min}
        step={props.step}
        style={{color:'#3b3b3b'}}
      />
    </div>
  );
}