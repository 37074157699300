/* eslint-disable */
import React, { Component } from 'react'
import { SketchPicker } from 'react-color';
import './main.css';
import CustomSlider from './slider';
import arow from '../automobile/arow.png'
import uparow from '../automobile/uparow.png'
import $ from "jquery";

export default class LeftMenu extends Component {

    constructor(props) {
        super(props)
        this.state = {
            hemisShowColorcodes: false,
            hemisColorArray: '',
            hemisStrength: 0.61,

            direShowColorcodes: false,
            direColorArray: '',
            direStrength: 0.54,

            ambiShowColorcodes: false,
            ambiColorArray: '',

            spotShowColorcodes: false,
            spotColorArray: '',

            pointShowColorcodes: false,
            pointColorArray: '',

            showHemisphereLight: false,
            showAmbientLight: false,
            showSpotLight: false,
            showPointLight: false,
        }
    }
    isHemisphereLightChange = (skycolor) => {
        if ((skycolor.hex == '' || skycolor.hex == undefined) && typeof (skycolor) != 'number')
            this.props.isHemisphereLightChange(!this.props.isHemisphereLight, this.state.hemisColorArray, this.state.hemisStrength)
        else if ((skycolor.hex !== '' || skycolor.hex !== undefined) && typeof (skycolor) != 'number') {
            this.setState({ hemisColorArray: skycolor.hex })
            this.props.isHemisphereLightChange(true, skycolor.hex, this.state.hemisStrength)

        }
        else {
            this.setState({ hemisStrength: skycolor })
            this.props.isHemisphereLightChange(true, this.state.hemisColorArray, skycolor)
        }
    }
    isDirectionalLightChange = (color) => {

        if (color.hex == undefined && typeof (color) != 'number') {

            // this.props.isDirectionalLightChange(!this.props.isDirectionalLight, this.state.direColorArray, this.state.direStrength)
        }
        else if (color.hex !== '' && typeof (color) != 'number') {

            this.setState({ direColorArray: color.hex })
            // this.props.isDirectionalLightChange(true, color.hex, this.state.direStrength)

        }
        else {
            this.setState({ direStrength: color })
            // this.props.isDirectionalLightChange(true, this.state.direColorArray, color)
        }
        // this.props.isDirectionalLightChange(!this.props.isDirectionalLight)
    }
    isAmbientLightChange = (color) => {
        if (color.hex == undefined)
            this.props.isAmbientLightChange(!this.props.isAmbientLight, this.state.ambiColorArray)
        else {
            this.setState({ ambiColorArray: color.hex })
            this.props.isAmbientLightChange(true, color.hex)
        }
    }
    isSpotLightChange = (color) => {

        if (color.hex == undefined)
            this.props.isSpotLightChange(!this.props.isSpotLight, this.state.spotColorArray)
        else {
            this.setState({ spotColorArray: color.hex })
            this.props.isSpotLightChange(true, color.hex)
        }

        // this.props.isSpotLightChange(!this.props.isSpotLight)
    }
    isPointLightChange = (color) => {
        if (color.hex == undefined) {
            this.props.isPointLightChange(!this.props.isPointLight, this.state.pointColorArray)
        } else if (color.hex != undefined) {
            this.setState({ pointColorArray: color.hex })
            this.props.isPointLightChange(true, color.hex)
        }

    }
    Hemisphere = () => {
        $('#Hemispherebtn').addClass('activebtn');
        $('#Ambientbtn').removeClass('activebtn');
        $('#Spotbtn').removeClass('activebtn');
        $('#Pointbtn').removeClass('activebtn');

        this.setState({
            showHemisphereLight: !this.state.showHemisphereLight,
            showAmbientLight: false,
            showSpotLight: false,
            showPointLight: false,
        })
    }
    Ambient = () => {
        $('#Ambientbtn').addClass('activebtn');
        $('#Hemispherebtn').removeClass('activebtn');
        $('#Spotbtn').removeClass('activebtn');
        $('#Pointbtn').removeClass('activebtn');

        this.setState({
            showHemisphereLight: false,
            showAmbientLight: !this.state.showAmbientLight,
            showSpotLight: false,
            showPointLight: false,
        })
    }
    Spot = () => {
        $('#Spotbtn').addClass('activebtn');
        $('#Hemispherebtn').removeClass('activebtn');
        $('#Ambientbtn').removeClass('activebtn');
        $('#Pointbtn').removeClass('activebtn');

        this.setState({
            showHemisphereLight: false,
            showAmbientLight: false,
            showSpotLight: !this.state.showSpotLight,
            showPointLight: false,
        })
    }
    Point = () => {
        $('#Pointbtn').addClass('activebtn');
        $('#Hemispherebtn').removeClass('activebtn');
        $('#Ambientbtn').removeClass('activebtn');
        $('#Spotbtn').removeClass('activebtn');

        this.setState({
            showHemisphereLight: false,
            showAmbientLight: false,
            showSpotLight: false,
            showPointLight: !this.state.showPointLight,
        })
    }
    render() {
        return (

            <div className="positionexteriorbtn righttoleft " id='showLigts'>
                {/* <div>
                    <img src={arow} />

                </div> */}

                <ul className="exteriorflex" id="exteriorList">
                    {/* <li>
                                <button className="commonbtn marignrightzero" id="projectname1" onClick={() => {
                                    this.setState({
                                        showTexture: !this.state.showTexture, showColor: false,
                                        showMetalness: false,
                                        showRoughness: false
                                    })
                                }}>Directional Light </button>
                            </li> */}
                    {this.props.isHemisphereLightShow &&
                        <li>
                            <button className="commonbtn marignrightzero flexbtn" id="Hemispherebtn" onClick={this.Hemisphere}>
                               <span style={{lineHeight:'1.1'}}>Hemisphere Light</span> 
                                <input id="chkHemisphereLight" type="checkbox" className="col-xs-10 marginRightFive" checked={this.props.isHemisphereLight} onChange={this.isHemisphereLightChange}></input>
                            </button>
                        </li>
                    }
                    {this.props.isAmbientLightShow &&
                        <li>
                            <button className="commonbtn marignrightzero flexbtn" id="Ambientbtn" onClick={this.Ambient}>
                            <span style={{lineHeight:'1.1'}}>Ambient Light</span> 

                                <input id="chkisAmbientLight" type="checkbox" className="col-xs-10 marginRightFive" checked={this.props.isAmbientLight} onChange={this.isAmbientLightChange}></input>
                            </button>
                        </li>
                    }
                    {this.props.isSpotLightShow &&
                        <li>
                            <button className="commonbtn marignrightzero flexbtn" id="Spotbtn" onClick={this.Spot} > 
                            <span style={{lineHeight:'1.1'}}>Spot Light</span> 

                                <input id="chkisSpotLight" type="checkbox" className="col-xs-10 marginRightFive" checked={this.props.isSpotLight} onChange={this.isSpotLightChange}></input>
                            </button>
                        </li>
                    }
                    {this.props.isPointLightShow &&
                        <li>
                            <button className="commonbtn marignrightzero flexbtn" id="Pointbtn" onClick={this.Point}>
                            <span style={{lineHeight:'1.1'}}>Point Light</span> 

                                <input id="chkPointLight" type="checkbox" className="col-xs-10 marginRightFive" checked={this.props.isPointLight} onChange={this.isPointLightChange}></input>
                            </button>
                        </li>
                    }

                </ul>


                <div >
                    {this.state.showHemisphereLight &&
                        <ul className="exteriorflex borderleft heightfull">
                            <li style={{ display: 'flex' }}>
                                <p className="marginbottomzero" style={{ marginRight: '10px' }}>Color:</p>

                                <p onClick={() => {
                                    this.setState({ hemisShowColorcodes: !this.state.hemisShowColorcodes })
                                }} className="colorbox marginbottomzero"></p>
                                {this.state.hemisShowColorcodes &&
                                    <div style={{ zIndex: 15000 }}>
                                        <SketchPicker color={this.state.hemisColorArray} onChangeComplete={(color) => { this.isHemisphereLightChange(color) }} />
                                    </div>
                                }
                            </li>
                            <li>
                                <p className="marginbottomzero">Light Strength:</p>

                            </li>
                            <li>
                                <CustomSlider sliderType="metalNess" metalNessChange={this.isHemisphereLightChange} max={10} min={0} step={0.5} defaultValue={0.5} />

                            </li>
                            {/* <React.Fragment>
                            <div className="row changeZDepth ">
                               
                            </div>
                            <div className="row changeZDepth ">
                            </div>
                        </React.Fragment> */}
                        </ul>
                    }
                    {this.state.showAmbientLight &&

                        <ul className="exteriorflex borderleft heightfull">
                            <li style={{ display: 'flex' }}>
                                <p className="marginbottomzero" style={{ marginRight: '10px' }}>Color:</p>

                                <p className="colorbox marginbottomzero" onClick={() => {
                                    this.setState({ ambiShowColorcodes: !this.state.ambiShowColorcodes })
                                }}></p>
                                {this.state.ambiShowColorcodes &&
                                    <div style={{ zIndex: 15000 }}>
                                        <SketchPicker color={this.state.ambiColorArray} onChangeComplete={(color) => { this.isAmbientLightChange(color) }} />
                                    </div>
                                }
                            </li>
                           
                        </ul>
                    }
                    {this.state.showSpotLight &&
                    
                          <ul className="exteriorflex borderleft heightfull">
                            <li style={{ display: 'flex' }}>
                                <p className="marginbottomzero" style={{ marginRight: '10px' }}>Color:</p>

                                <p onClick={() => {
                                this.setState({ spotShowColorcodes: !this.state.spotShowColorcodes })
                            }} className="colorbox marginbottomzero"></p>
                            {this.state.spotShowColorcodes &&
                                <div style={{ zIndex: 15000 }}>
                                    <SketchPicker color={this.state.spotColorArray} onChangeComplete={(color) => { this.isSpotLightChange(color) }} />
                                </div>
                            }
                            </li>
                           
                        </ul>

                      
                    }
                    {this.state.showPointLight && 
                         <ul className="exteriorflex borderleft heightfull">
                            <li style={{ display: 'flex' }}>
                                <p className="marginbottomzero" style={{ marginRight: '10px' }}>Color:</p>

                                <p onClick={() => {
                                this.setState({ pointShowColorcodes: !this.state.pointShowColorcodes })
                            }} className="colorbox marginbottomzero"></p>
                            {this.state.pointShowColorcodes &&
                                <div style={{ zIndex: 15000 }}>
                                    <SketchPicker color={this.state.pointColorArray} onChangeComplete={(color) => { this.isPointLightChange(color) }} />
                                </div>
                            }
                            </li>
                           
                        </ul>
                       
                    }

                </div>
            </div>



            // <div className="rotatemodelbox" style={{ marginLeft: '15px' }}>
            //     <div className=" secondary marginleftrotatepanel" id="secondary">
            //         <div>
            //             {this.props.isHemisphereLightShow &&
            //                 <React.Fragment>
            //                     <div className="row changeSceneName firstRow">
            //                         <p className="col-xs-5 optionName marginRightFive margleft ">Hemisphere Light</p>
            //                         <input id="chkHemisphereLight" type="checkbox" className="col-xs-10 marginRightFive" checked={this.props.isHemisphereLight} onChange={this.isHemisphereLightChange}></input>
            //                     </div>
            //                     {this.props.isHemisphereLight &&
            //                         <React.Fragment>
            //                             <div className="row changeZDepth ">
            //                                 <p onClick={() => {
            //                                     this.setState({ hemisShowColorcodes: !this.state.hemisShowColorcodes })
            //                                 }} className="col-xs-5 optionName marginRightFive margleft ">Sky Color</p>
            //                                 {this.state.hemisShowColorcodes &&
            //                                     <div style={{ zIndex: 15000 }}>
            //                                         <SketchPicker color={this.state.hemisColorArray} onChangeComplete={(color) => { this.isHemisphereLightChange(color) }} />
            //                                     </div>
            //                                 }
            //                             </div>
            //                             <div className="row changeZDepth ">
            //                                 <p className="col-xs-5 optionName marginRightFive width ">Light strength</p>
            //                                 <CustomSlider sliderType="metalNess" metalNessChange={this.isHemisphereLightChange} max={10} min={0} step={0.5} defaultValue={0.5} />
            //                             </div>
            //                         </React.Fragment>
            //                     }
            //                 </React.Fragment>
            //             }
            //             {/* {this.props.isDirectionalLightShow &&
            //                 <React.Fragment>
            //                     <div className="row changeSceneName firstRow">
            //                         <p className="col-xs-5 optionName marginRightFive margleft ">Directional Light</p>
            //                         <input id="chkisDirectionalLight" type="checkbox" className="col-xs-10 marginRightFive" checked={this.props.isDirectionalLight} onChange={this.isDirectionalLightChange}></input>
            //                     </div>
            //                     {this.props.isDirectionalLight &&
            //                         <React.Fragment>
            //                             <div className="row changeZDepth ">
            //                                 <p onClick={() => {
            //                                     this.setState({ direShowColorcodes: !this.state.direShowColorcodes })
            //                                 }} className="col-xs-5 optionName marginRightFive margleft ">Sky Color</p>
            //                                 {this.state.direShowColorcodes &&
            //                                     <div style={{ zIndex: 15000 }}>
            //                                         <SketchPicker color={this.state.direColorArray} onChangeComplete={(color) => { this.isDirectionalLightChange(color) }} />
            //                                     </div>
            //                                 }
            //                             </div>
            //                             <div className="row changeZDepth ">
            //                                 <p className="col-xs-5 optionName marginRightFive width ">Light strength</p>
            //                                 <CustomSlider sliderType="metalNess" metalNessChange={this.isDirectionalLightChange} max={10} min={0} step={0.5} defaultValue={0.5} />
            //                             </div>
            //                         </React.Fragment>
            //                     }
            //                 </React.Fragment>
            //             } */}
            //             {this.props.isAmbientLightShow &&
            //                 <React.Fragment>
            //                     <div className="row changeSceneName firstRow">
            //                         <p className="col-xs-5 optionName marginRightFive margleft ">Ambient Light</p>
            //                         <input id="chkisAmbientLight" type="checkbox" className="col-xs-10 marginRightFive" checked={this.props.isAmbientLight} onChange={this.isAmbientLightChange}></input>
            //                     </div>
            //                     {this.props.isAmbientLight &&
            //                         <div className="row changeZDepth ">
            //                             <p onClick={() => {
            //                                 this.setState({ ambiShowColorcodes: !this.state.ambiShowColorcodes })
            //                             }} className="col-xs-5 optionName marginRightFive margleft ">Sky Color</p>
            //                             {this.state.ambiShowColorcodes &&
            //                                 <div style={{ zIndex: 15000 }}>
            //                                     <SketchPicker color={this.state.ambiColorArray} onChangeComplete={(color) => { this.isAmbientLightChange(color) }} />
            //                                 </div>
            //                             }
            //                         </div>
            //                     }
            //                 </React.Fragment>
            //             }
            //             {this.props.isSpotLightShow &&
            //                 <React.Fragment>
            //                     <div className="row changeSceneName firstRow">
            //                         <p className="col-xs-5 optionName marginRightFive margleft ">Spot Light</p>
            //                         <input id="chkisSpotLight" type="checkbox" className="col-xs-10 marginRightFive" checked={this.props.isSpotLight} onChange={this.isSpotLightChange}></input>
            //                     </div>
            //                     {this.props.isSpotLight &&
            //                         <div className="row changeZDepth ">
            //                             <p onClick={() => {
            //                                 this.setState({ spotShowColorcodes: !this.state.spotShowColorcodes })
            //                             }} className="col-xs-5 optionName marginRightFive margleft ">Sky Color</p>
            //                             {this.state.spotShowColorcodes &&
            //                                 <div style={{ zIndex: 15000 }}>
            //                                     <SketchPicker color={this.state.spotColorArray} onChangeComplete={(color) => { this.isSpotLightChange(color) }} />
            //                                 </div>
            //                             }
            //                         </div>
            //                     }
            //                 </React.Fragment>
            //             }
            //             {this.props.isPointLightShow &&
            //                 <React.Fragment>
            //                     <div className="row changeSceneName firstRow">
            //                         <p className="col-xs-5 optionName marginRightFive margleft ">Point Light</p>
            //                         <input id="chkPointLight" type="checkbox" className="col-xs-10 marginRightFive" checked={this.props.isPointLight} onChange={this.isPointLightChange}></input>
            //                     </div>
            //                     {this.props.isPointLight &&
            //                         <div className="row changeZDepth ">
            //                             <p onClick={() => {
            //                                 this.setState({ pointShowColorcodes: !this.state.pointShowColorcodes })
            //                             }} className="col-xs-5 optionName marginRightFive margleft ">Sky Color</p>
            //                             {this.state.pointShowColorcodes &&
            //                                 <div style={{ zIndex: 15000 }}>
            //                                     <SketchPicker color={this.state.pointColorArray} onChangeComplete={(color) => { this.isPointLightChange(color) }} />
            //                                 </div>
            //                             }
            //                         </div>
            //                     }
            //                 </React.Fragment>
            //             }
            //         </div>
            //     </div>
            // </div>
        )
    }
}
