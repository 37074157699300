/* eslint-disable */
import React, { Component } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import $ from "jquery";

import axios from "axios";
import LeftMenu from "./Component/LeftMenu";
import SecoudMenu from "./Component/SecoudMenu";
import BottomMenu from "./Component/BottomMenu";
import menuimg from "./automobile/menu.png";
import closeimg from "./automobile/close.png";
import logoimg from "./automobile/udhyog-20210701125657.png";
import HelpIcon from "@material-ui/icons/Help";
import Dialog from "@material-ui/core/Dialog";
import Vidoes from "./Videos/Customizer-Hint.mp4";
import moment from "moment";
import Sectioning from "./Sectioning";

let Container;

var loaded = false;
let camera, scene, renderer;

let hdrCubeRenderTarget;
let textureEquirec;
let initRotate = 0;
let theModel;
let raycaster = new THREE.Raycaster();
let mouse = new THREE.Vector2();
let cubeRenderTarget;
let isSetDiamond = true;

export default class Viewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modelPath: "",
      isProjectAvailable: true,

      modelData: [],
      colorCodes: [],
      images: [],
      selectedMeshName: "",
      isHemisphereLightShow: false,
      isDirectionalLightShow: false,
      isAmbientLightShow: false,
      isSpotLightShow: false,
      isPointLightShow: false,
      isHemisphereLight: false,
      isDirectionalLight: false,
      isAmbientLight: false,
      isSpotLight: false,
      isPointLight: false,
      isVisible: true,
      isDiamond: false,
      isMetal: false,
      isStone: false,
      metalType: {},
      isGridEnabled: true,
      myInfo: false,
      isSectioning: false,

      uid: "",
      projectId: "",
      projectName: "",
    };
    this.animate = this.animate.bind(this);
    this.handleInfo = this.handleInfo.bind(this);
  }

  componentDidMount() {
    try {
      window.addEventListener("resize", this.onWindowResize, false);

      let currentProjectId = window.location.href;
      currentProjectId = currentProjectId.substring(
        currentProjectId.lastIndexOf("/") + 1
      );
      this.getProjectDetails(currentProjectId);

      $("#menubtn").on("click", function () {
        $("#Menu-automobile").addClass("lefttoright");
        $("#Header").addClass("toptobottom");
        $("#Menu-automobile").removeClass("righttoleft");
        $("#Header").removeClass("bottomtotopheader");
      });

      $("#closebtn").on("click", function () {
        $("#Menu-automobile").removeClass("lefttoright");
        $("#Header").removeClass("toptobottom");
        $("#Menu-automobile").addClass("righttoleft");
        $("#Header").addClass("bottomtotopheader");
        $("#showLigts").removeClass("lefttoright");
        $("#showLigts").addClass("righttoleft");

        $("#bottomMenu").removeClass("lefttoright");
        $("#bottomMenu").addClass("righttoleft");
      });

      $("#lightdbtn").on("click", function () {
        $("#openexterior").removeClass("lefttoright");
        $("#openexterior").addClass("righttoleft");
      });
    } catch (err) {
      this.errorLog("componentDidMount", err, "");
    }
  }

  /**
   * this function is use for get project details
   *
   * @param {project id} projectId
   */
  getProjectDetails = (projectId) => {
    return axios
      .get(`https://ptpvr.com/projects/${projectId}`, {
        headers: { "x-access-token": "null" },
      })
      .then((res) => {
        let project = res.data;

        this.setState({
          uid: project.uid,
          projectId: project._id,
          projectName: project.projectname,
        });

        axios
          .post("https://ptpvr.com/paidview/isviewvalid", {
            product: "Udyog",
            uid: project.uid,
          })
          .then((resview) => {
            if (resview.data.isAllowed) {
              axios
                .put(`https://ptpvr.com/projects/updateview/${project._id}`, {
                  views: project.views + 1,
                })
                .then((response) => {
                  if (resview.data.isPayAsYouGo) {
                    this.postSession(res.data.uid);
                  }
                })
                .catch((error) => {
                  this.errorLog("updateview Service", error, "");
                });

              let cal_usage = 0;
              let final_usage = 0;

              project.scenes[0].gltfmodels.map((obj) => {
                cal_usage += obj.size;
              });

              final_usage = cal_usage / 1024;
              this.calculateUsage(project.uid, final_usage);

              this.setState(
                {
                  isGridEnabled: project.scenes[0].isGridEnabled,
                  modelPath: project.scenes[0].gltfmodels[0].src,
                  modelData: res.data.scenes[0].gltfmodels,
                },
                () => {
                  this.init();
                  this.animate();

                  this.state.modelData.map((obj) => {
                    if (
                      !this.state.isHemisphereLight &&
                      obj.customizer.isHemisphereLight
                    ) {
                      this.setState(
                        {
                          isHemisphereLightShow:
                            obj.customizer.isHemisphereLight,
                          isHemisphereLight: obj.customizer.isHemisphereLight,
                        },
                        () => {
                          this.isHemisphereLightChange(
                            this.state.isHemisphereLight
                          );
                        }
                      );
                    }
                    if (
                      !this.state.isDirectionalLight &&
                      obj.customizer.isDirectionalLight
                    ) {
                      this.setState(
                        {
                          isDirectionalLightShow:
                            obj.customizer.isDirectionalLight,
                          isDirectionalLight: obj.customizer.isDirectionalLight,
                        },
                        () => {
                          // this.isDirectionalLightChange(this.state.isDirectionalLight)
                        }
                      );
                    }
                    if (
                      !this.state.isAmbientLight &&
                      obj.customizer.isAmbientLight
                    ) {
                      this.setState(
                        {
                          isAmbientLightShow: obj.customizer.isAmbientLight,
                          isAmbientLight: obj.customizer.isAmbientLight,
                        },
                        () => {
                          this.isAmbientLightChange(this.state.isAmbientLight);
                        }
                      );
                    }
                    if (!this.state.isSpotLight && obj.customizer.isSpotLight) {
                      this.setState(
                        {
                          isSpotLightShow: obj.customizer.isSpotLight,
                          isSpotLight: obj.customizer.isSpotLight,
                        },
                        () => {
                          this.isSpotLightChange(this.state.isSpotLight);
                        }
                      );
                    }
                    if (
                      !this.state.isPointLight &&
                      obj.customizer.isPointLight
                    ) {
                      this.setState(
                        {
                          isPointLightShow: obj.customizer.isPointLight,
                          isPointLight: obj.customizer.isPointLight,
                        },
                        () => {
                          this.isPointLightChange(this.state.isPointLight);
                        }
                      );
                    }
                  });
                }
              );
              this.creatorUserLog(
                "Udyog Customizer",
                project.uid,
                project._id,
                project.projectname
              );
            } else {
              window.location.href = "/warning";
            }
          })
          .catch((err) => {
            this.errorLog("isviewvalid", err, "");
          });
      })
      .catch((err) => {
        this.setState({ isProjectAvailable: false });
        this.errorLog("getProjectDetails", err, "");
      });
  };

  postSession = (uid) => {
    axios
      .post("https://ptpvr.com/usage/session", {
        uid: uid,
        product: "udyog",
      })
      .then((response) => {})
      .catch((err) => {
        this.errorLog("postSession", err, "");
      });
  };

  calculateUsage = (uid, usage) => {
    axios
      .post("https://ptpvr.com/usage", {
        uid: uid,
        usage: usage,
        product: "udyog",
      })
      .then((result) => {})
      .catch((err) => {
        this.errorLog("calculateUsage", err, "");
      });
  };

  creatorUserLog = (interactionEvent, uid, projectId, projectName) => {
    const serverTimeObj = {
      serverDate: "",
      serverOffset: "",
    };

    axios
      .get(`https://ptpvr.com/serverTime`)
      .then((res) => {
        // server returns a json object with a date property.
        // serverTimeObj.serverDate = res.data.date;
        // serverTimeObj.serverOffset = moment(res.data.date).diff(new Date());

        // const temp = moment().add('milliseconds', moment(res.data.date).diff(new Date()))
        // const temp1 = temp._d
        // const serverDate = new Date(temp1).getTime()

        // alert(new Date(temp1).getTime())

        let ip_address = "";
        let queryString = window.location.search;
        let query_source = new URLSearchParams(queryString);
        let utm_source = query_source.get("utm_source");
        let utm_medium = query_source.get("utm_medium");
        let utm_content = query_source.get("utm_content");
        let utm_campaign = query_source.get("utm_campaign");
        let utm_term = query_source.get("utm_term");

        var module = {
          options: [],
          header: [
            navigator.platform,
            navigator.userAgent,
            navigator.appVersion,
            navigator.vendor,
            window.opera,
          ],
          dataos: [
            { name: "Windows Phone", value: "Windows Phone", version: "OS" },
            { name: "Windows", value: "Win", version: "NT" },
            { name: "iPhone", value: "iPhone", version: "OS" },
            { name: "iPad", value: "iPad", version: "OS" },
            { name: "Kindle", value: "Silk", version: "Silk" },
            { name: "Android", value: "Android", version: "Android" },
            { name: "PlayBook", value: "PlayBook", version: "OS" },
            { name: "BlackBerry", value: "BlackBerry", version: "/" },
            { name: "Macintosh", value: "Mac", version: "OS X" },
            { name: "Linux", value: "Linux", version: "rv" },
            { name: "Palm", value: "Palm", version: "PalmOS" },
          ],
          databrowser: [
            { name: "Chrome", value: "Chrome", version: "Chrome" },
            { name: "Firefox", value: "Firefox", version: "Firefox" },
            { name: "Safari", value: "Safari", version: "Version" },
            { name: "Internet Explorer", value: "MSIE", version: "MSIE" },
            { name: "Opera", value: "Opera", version: "Opera" },
            { name: "BlackBerry", value: "CLDC", version: "CLDC" },
            { name: "Mozilla", value: "Mozilla", version: "Mozilla" },
          ],
          init: function () {
            var agent = this.header.join(" "),
              os = this.matchItem(agent, this.dataos),
              browser = this.matchItem(agent, this.databrowser);

            return { os: os, browser: browser };
          },
          matchItem: function (string, data) {
            var i = 0,
              j = 0,
              html = "",
              regex,
              regexv,
              match,
              matches,
              version;

            for (i = 0; i < data.length; i += 1) {
              regex = new RegExp(data[i].value, "i");
              match = regex.test(string);
              if (match) {
                regexv = new RegExp(data[i].version + "[- /:;]([\\d._]+)", "i");
                matches = string.match(regexv);
                version = "";
                if (matches) {
                  if (matches[1]) {
                    matches = matches[1];
                  }
                }
                if (matches) {
                  matches = matches.split(/[._]+/);
                  for (j = 0; j < matches.length; j += 1) {
                    if (j === 0) {
                      version += matches[j] + ".";
                    } else {
                      version += matches[j];
                    }
                  }
                } else {
                  version = "0";
                }
                return { name: data[i].name, version: parseFloat(version) };
              }
            }
            return { name: "unknown", version: 0 };
          },
        };

        var e = module.init();
        if (e.browser.name === "Safari") {
          var isSafari =
            /(Mac|iPhone|iPod|iPad)/i.test(window.navigator.userAgent) &&
            /WebKit/i.test(window.navigator.userAgent) &&
            !/(CriOS|FxiOS|OPiOS|mercury)/i.test(window.navigator.userAgent);
          var isChrome = navigator.userAgent.indexOf("Chrome") > -1;

          if (!isSafari || isChrome) {
            e.browser.name = "Chrome";
          }
        }

        const URL = `https://ptpvr.com/userlogs`;

        let myipPromise = new Promise((resolve, reject) => {
          fetch("https://api.ipify.org/?format=json")
            .then((results) => {
              if (!results.ok) {
                results.text().then((text) => {
                  throw Error(text);
                });
              } else {
                return results.json();
              }
            })
            .then((data) => {
              ip_address = data.ip.toString();
              resolve();
            })
            .catch((err) => {
              reject(err);
            });
        });

        myipPromise
          .then(() => {
            let data = {
              name: "",
              email: localStorage.getItem("emailId"),
              datetime: new Date().valueOf(),
              productType: "Melzo Udyog",
              interactionEvent: interactionEvent, // 'Ar Hand ',
              osName: e.os.name,
              osVersion: e.os.version,
              browser: e.browser.name,
              browserVersion: e.browser.version,
              navigatorUseragent: navigator.userAgent,
              navigatorAppVersion: navigator.appVersion,
              navigatorPlatform: navigator.platform,
              navigatorVendor: navigator.vendor,
              utm_source: utm_source,
              utm_campaign: utm_campaign,
              utm_medium: utm_medium,
              utm_content: utm_content,
              utm_term: utm_term,
              ipAddress: ip_address,
              uid: uid,
              projectId: projectId,
              projectName: projectName,
            };

            axios(URL, { method: "POST", data })
              .then((response) => {})
              .catch((error) => {
                throw error;
              });
          })
          .catch((err) => {
            this.errorLog("User Log Error", err, "");
          });
      })
      .catch((err) => {
        this.errorLog("creatorUserLog", err, "");
      });
  };

  errorLog = (interactionEvent, errorMessage, otherMessage) => {
    const serverTimeObj = {
      serverDate: "",
      serverOffset: "",
    };

    axios
      .get(`https://ptpvr.com/serverTime`)
      .then((res) => {
        // server returns a json object with a date property.
        serverTimeObj.serverDate = res.data.date;
        serverTimeObj.serverOffset = moment(res.data.date).diff(new Date());

        const temp = moment().add(
          "milliseconds",
          moment(res.data.date).diff(new Date())
        );
        const temp1 = temp._d;
        const serverDate = new Date(temp1).getTime();

        let queryString = window.location.search;
        let query_source = new URLSearchParams(queryString);
        let utm_source = query_source.get("utm_source");
        let utm_medium = query_source.get("utm_medium");
        let utm_content = query_source.get("utm_content");
        let utm_campaign = query_source.get("utm_campaign");
        let utm_term = query_source.get("utm_term");
        let userName = query_source.get("userName");
        let userEmail = query_source.get("userEmail");
        let userContactNo = query_source.get("userContactNo");

        var module = {
          options: [],
          header: [
            navigator.platform,
            navigator.userAgent,
            navigator.appVersion,
            navigator.vendor,
            window.opera,
          ],
          dataos: [
            {
              name: "Windows Phone",
              value: "Windows Phone",
              version: "OS",
            },
            {
              name: "Windows",
              value: "Win",
              version: "NT",
            },
            {
              name: "iPhone",
              value: "iPhone",
              version: "OS",
            },
            {
              name: "iPad",
              value: "iPad",
              version: "OS",
            },
            {
              name: "Kindle",
              value: "Silk",
              version: "Silk",
            },
            {
              name: "Android",
              value: "Android",
              version: "Android",
            },
            {
              name: "PlayBook",
              value: "PlayBook",
              version: "OS",
            },
            {
              name: "BlackBerry",
              value: "BlackBerry",
              version: "/",
            },
            {
              name: "Macintosh",
              value: "Mac",
              version: "OS X",
            },
            {
              name: "Linux",
              value: "Linux",
              version: "rv",
            },
            {
              name: "Palm",
              value: "Palm",
              version: "PalmOS",
            },
          ],
          databrowser: [
            {
              name: "Chrome",
              value: "Chrome",
              version: "Chrome",
            },
            {
              name: "Firefox",
              value: "Firefox",
              version: "Firefox",
            },
            {
              name: "Safari",
              value: "Safari",
              version: "Version",
            },
            {
              name: "Internet Explorer",
              value: "MSIE",
              version: "MSIE",
            },
            {
              name: "Opera",
              value: "Opera",
              version: "Opera",
            },
            {
              name: "BlackBerry",
              value: "CLDC",
              version: "CLDC",
            },
            {
              name: "Mozilla",
              value: "Mozilla",
              version: "Mozilla",
            },
          ],
          init: function () {
            var agent = this.header.join(" "),
              os = this.matchItem(agent, this.dataos),
              browser = this.matchItem(agent, this.databrowser);

            return {
              os: os,
              browser: browser,
            };
          },
          matchItem: function (string, data) {
            var i = 0,
              j = 0,
              html = "",
              regex,
              regexv,
              match,
              matches,
              version;

            for (i = 0; i < data.length; i += 1) {
              regex = new RegExp(data[i].value, "i");
              match = regex.test(string);
              if (match) {
                regexv = new RegExp(data[i].version + "[- /:;]([\\d._]+)", "i");
                matches = string.match(regexv);
                version = "";
                if (matches) {
                  if (matches[1]) {
                    matches = matches[1];
                  }
                }
                if (matches) {
                  matches = matches.split(/[._]+/);
                  for (j = 0; j < matches.length; j += 1) {
                    if (j === 0) {
                      version += matches[j] + ".";
                    } else {
                      version += matches[j];
                    }
                  }
                } else {
                  version = "0";
                }
                return {
                  name: data[i].name,
                  version: parseFloat(version),
                };
              }
            }
            return {
              name: "unknown",
              version: 0,
            };
          },
        };

        var e = module.init();
        if (e.browser.name === "Safari") {
          var isSafari =
            /(Mac|iPhone|iPod|iPad)/i.test(window.navigator.userAgent) &&
            /WebKit/i.test(window.navigator.userAgent) &&
            !/(CriOS|FxiOS|OPiOS|mercury)/i.test(window.navigator.userAgent);
          var isChrome = navigator.userAgent.indexOf("Chrome") > -1;

          if (!isSafari || isChrome) {
            e.browser.name = "Chrome";
          }
        }

        const URL = `https://ptpvr.com/errorlog/add`;
        let ip_address = "";

        let myipPromise = new Promise((resolve, reject) => {
          fetch("https://api.ipify.org/?format=json")
            .then((results) => {
              if (!results.ok) {
                results.text().then((text) => {
                  throw Error(text);
                });
              } else {
                return results.json();
              }
            })
            .then((data) => {
              ip_address = data.ip.toString();
              resolve();
            })
            .catch((err) => {
              reject(err);
            });
        });

        myipPromise
          .then(() => {
            let data = {
              name: "",
              mobile: "",
              expo: "",
              email: localStorage.getItem("emailId"),
              dateTime: serverDate,
              productType: "Melzo Udyog",
              productName: "Melzo Udyog",
              interactionEvent: interactionEvent, // 'Ar Hand ',
              osName: e.os.name,
              osVersion: e.os.version,
              browser: e.browser.name,
              browserVersion: e.browser.version,
              navigatorUseragent: navigator.userAgent,
              navigatorAppVersion: navigator.appVersion,
              navigatorPlatform: navigator.platform,
              navigatorVendor: navigator.vendor,
              utm_source: utm_source,
              utm_campaign: utm_campaign,
              utm_medium: utm_medium,
              utm_content: utm_content,
              utm_term: utm_term,
              ipAddress: ip_address,
              uid: this.state.uid,
              projectId: this.state.projectId,
              projectName: this.state.projectName,
              errorMessage: errorMessage.message,
              otherMessage: otherMessage,
              userName: userName
                ? userName
                : localStorage.getItem("userName")
                ? localStorage.getItem("userName")
                : "",
              userEmail: userEmail
                ? userEmail
                : localStorage.getItem("userEmail")
                ? localStorage.getItem("userEmail")
                : "",
              userContactNo: userContactNo
                ? userContactNo
                : localStorage.getItem("userContactNo")
                ? localStorage.getItem("userContactNo")
                : "",
            };

            axios(URL, {
              method: "POST",
              data,
            })
              .then((response) => {})
              .catch((error) => {
                throw error;
              });
          })
          .catch((err) => {
            throw err;
          });
      })
      .catch((err) => {});
  };

  init = () => {
    try {
      let obj = this.state.modelData[0];
      Container = document.createElement("div");
      Container.setAttribute("id", "modelData");
      document.body.appendChild(Container);

      // camera = new THREE.PerspectiveCamera(
      //   40,
      //   window.innerWidth / window.innerHeight,
      //   1,
      //   10000
      // );
      camera = new THREE.PerspectiveCamera( 36, window.innerWidth / window.innerHeight, 1, 1000 );
      camera.position.set( - 1.5, 2.5, 3.0 );
      
      // camera.position.set(0.0, 500, 500);

      const light1 = new THREE.AmbientLight(0xffffff, 0.3);
      light1.name = "ambient_light";
      camera.add(light1);

      scene = new THREE.Scene();

      scene.background = new THREE.Color(0xffffff);

      renderer = new THREE.WebGLRenderer({ antialias: true });
      let newColor = new THREE.Color(0xffffff);
      renderer.localClippingEnabled = true;
      cubeRenderTarget = new THREE.WebGLCubeRenderTarget(128, {
        format: THREE.RGBFormat,
        generateMipmaps: true,
        minFilter: THREE.LinearMipmapLinearFilter,
      });

      // Create cube camera
      // const cubeCamera = new THREE.CubeCamera(1, 100000, cubeRenderTarget);
      // scene.add(cubeCamera);

      let textureLoader = new THREE.TextureLoader();

      textureEquirec = textureLoader.load(
        "https://threejs.org/examples/textures/2294472375_24a3b8ef46_o.jpg"
      );
      textureEquirec.mapping = THREE.EquirectangularReflectionMapping;
      textureEquirec.encoding = THREE.sRGBEncoding;

      if (this.state.isGridEnabled) {
        const gridHelper = new THREE.GridHelper(500, 500);
        gridHelper.position.set(0, 0, 0);
        scene.add(gridHelper);
      }

      // glb model
      const loader = new GLTFLoader();
      let pmremGenerator = new THREE.PMREMGenerator(renderer);
      let finalFilePath = this.state.modelPath;
      new RGBELoader()
        .setDataType(THREE.UnsignedByteType)
        .setPath("https://threejs.org/examples/textures/equirectangular/")
        .load("royal_esplanade_1k.hdr", function (hdrEquirect) {
          hdrCubeRenderTarget =
            pmremGenerator.fromEquirectangular(hdrEquirect).texture;
          const BACKGROUND_COLOR = 0xf1f1f1;
          scene.background = new THREE.Color(BACKGROUND_COLOR);
          scene.environment = hdrCubeRenderTarget;
          pmremGenerator.dispose();
          hdrEquirect.dispose();
          console.log("finalFilePath",finalFilePath)
          loader.load(
            "https://pptvr-3d-models.s3.ap-south-1.amazonaws.com/" +
              finalFilePath,
            (gltf) => {
                console.log("gltf.scene",gltf.scene)
              theModel = gltf.scene;
              theModel.rotation._x = obj.rotation.x;
              theModel.rotation._y = obj.rotation.y;
              theModel.rotation._z = obj.rotation.z;
              theModel.position.x = obj.position.x;
              theModel.position.y = obj.position.y - 3;
              theModel.position.z = obj.position.z;
              theModel.scale.x = obj.scale.x;
              theModel.scale.y = obj.scale.y;
              theModel.scale.z = obj.scale.z;
              scene.add(theModel);
              console.log("theModel", theModel);
              var box = new THREE.Box3().setFromObject(gltf.scene);
              camera.position.set(
                0,
                box.getSize().y + 10,
                box.getSize().z + 20
              );
            }
          );
        });

      
      pmremGenerator.compileEquirectangularShader();

      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.shadowMap.enabled = true;
      Container.appendChild(renderer.domElement);

      renderer.outputEncoding = THREE.sRGBEncoding;

      // const sun = new THREE.DirectionalLight(0xffffcc)
      // sun.position.set(5.000, 10, 7.5)
      // scene.add(sun)

      // const sun1 = new THREE.DirectionalLight(0xffffcc)
      // sun1.position.set(-5.680, 10, -8.305)
      // scene.add(sun1)

      let controls = new OrbitControls(camera, renderer.domElement);
      controls.target.set( 0, 1, 0 );
				controls.update();
        controls.addEventListener( 'change', render );

      function animate() {
        controls.update();
        renderer.render(scene, camera);
        requestAnimationFrame(animate);
        // console.log("canvas renderer",renderer)
        if (resizeRendererToDisplaySize(renderer)) {
          const canvas = renderer.domElement;
          camera.aspect = canvas.clientWidth / canvas.clientHeight;
          camera.updateProjectionMatrix();
          console.log("canvas renderer", canvas, renderer);
        }

        // if (theModel != null && loaded == false) {
        //     initialRotation();
        // }
      }

      // animate();

      function initialRotation() {
        initRotate++;
        if (initRotate <= 120) {
          theModel.rotation.y += Math.PI / 60;
        } else {
          loaded = true;
        }
      }

      function render() {
        console.log("render")
        renderer.render( scene, camera );

    }

      function resizeRendererToDisplaySize(renderer) {
        const canvas = renderer.domElement;
        var width = window.innerWidth;
        var height = window.innerHeight;
        var canvasPixelWidth = canvas.width / window.devicePixelRatio;
        var canvasPixelHeight = canvas.height / window.devicePixelRatio;

        const needResize =
          canvasPixelWidth !== width || canvasPixelHeight !== height;
        if (needResize) {
          renderer.setSize(width, height, false);
        }
        return needResize;
      }

      window.addEventListener("resize", this.onWindowResize(), false);
      renderer.domElement.addEventListener("click", this.onClick, false);
    } catch (err) {
      this.errorLog("init Model Load", err, "");
    }
  };

  onClick = (event) => {
    try {
      event.preventDefault();

      let breakLoop = 0;
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

      raycaster.setFromCamera(mouse, camera);

      var intersects = raycaster.intersectObjects(scene.children, true);
      if (intersects[0] != undefined) {
        this.checkGlassEffect();
        // let seleModelName = intersects[0].object.name.substring(0, intersects[0].object.name.lastIndexOf('_') == -1 ? intersects[0].object.name.length : intersects[0].object.name.lastIndexOf('_'))
        this.setState(
          {
            selectedMeshName: intersects[0].object.name,
            colorCodes: [],
            images: [],
            colorChange: {},
            isMetal: false,
            isDiamond: false,
            isStone: false,
            metalType: {},
          },
          () => {
            let tempcolorCodes = [];
            let tempimages = [];
            console.log("this.state.modelData",this.state.modelData)
            for (let i = 0; i < this.state.modelData.length; i++) {
              for (
                let j = 0;
                j < this.state.modelData[i].customizer.texture.length;
                j++
              ) {
                let modelName =
                  this.state.modelData[i].customizer.texture[j].partName;
                if (modelName) {
                  if (modelName.indexOf(".")) {
                    modelName = this.state.modelData[i].customizer.texture[
                      j
                    ].partName.replace(".", "");
                  }

                  if (
                    breakLoop == 0 &&
                    // modelName.substring(0, modelName.lastIndexOf('_') == -1 ? modelName.length : modelName.lastIndexOf('_')) === this.state.selectedMeshName) {
                    modelName === this.state.selectedMeshName
                  ) {
                    breakLoop = 1;
                    $("#showLigts").removeClass("lefttoright");
                    $("#showLigts").addClass("righttoleft");

                    $("#openexterior").toggleClass("lefttoright");
                    $("#openexterior").toggleClass("righttoleft");
                    console.log("this.state.modelData[i].customizer.texture[j].textureColor",this.state.modelData[i].customizer.texture[j].textureColor)
                    this.setState({
                      showExterior: true,
                      colorCodes:
                        this.state.modelData[i].customizer.texture[j]
                          .textureColor,
                      images:
                        this.state.modelData[i].customizer.texture[j]
                          .textureImage,
                      isVisible:
                        this.state.modelData[i].customizer.texture[j].isVisible,
                      isMetal:
                        this.state.modelData[i].customizer.texture[j].isMetal,
                      isDiamond:
                        this.state.modelData[i].customizer.texture[j].isDiamond,
                      isStone:
                        this.state.modelData[i].customizer.texture[j].isStone,
                      metalType:
                        this.state.modelData[i].customizer.texture[j].metalType,
                    });
                    break;
                  }
                }
              }
              break;
            }
          }
        );
      }
    } catch (err) {
      this.errorLog("onClick ", err, "Model OnClick");
    }
  };

  onWindowResize = () => {
    try {
      let width = window.innerWidth;
      let height = window.innerHeight;

      camera.aspect = width / height;
      camera.updateProjectionMatrix();

      renderer.setSize(width, height);
    } catch (err) {
      this.errorLog("onWindowResize ", err, "");
    }
  };

  animate() {
    try {
      requestAnimationFrame(this.animate);
      this.onMaterialColor();
    } catch (err) {
      this.errorLog("animate ", err, "");
    }
  }

  onMaterialColor = () => {
    try {
      camera.lookAt(scene.position);
      renderer.render(scene, camera);
    } catch (err) {
      this.errorLog("onMaterialColor ", err, "");
    }
  };

  colorChange = (colorCode) => {
    try {
      let selectedMeshName = this.state.selectedMeshName;
      let color = new THREE.Color(colorCode);
      theModel.traverse((node) => {
        if (node.name === selectedMeshName && node.material != undefined) {
          node.material.color = color;
        }
      });
    } catch (err) {
      this.errorLog("colorChange ", err, "");
    }
  };

  imageChange = (image) => {
    try {
      let selectedMeshName = this.state.selectedMeshName;
      theModel.traverse((node) => {
        if (node.name === selectedMeshName && node.material != undefined) {
          let txt = new THREE.TextureLoader().load(
            "https://pptvr-images.s3.ap-south-1.amazonaws.com/" + image
          );
          txt.wrapS = THREE.RepeatWrapping;
          txt.wrapT = THREE.RepeatWrapping;
          let new_mtl = new THREE.MeshPhongMaterial({
            map: txt,
            metalness: 0,
            roughness: 1,
          });
          node.material = new_mtl;
        }
      });
    } catch (err) {
      this.errorLog("imageChange ", err, "");
    }
  };

  handleInfo() {
    try {
      this.setState({ myInfo: true });
    } catch (err) {
      this.errorLog("handleInfo ", err, "");
    }
  }

  handleClose = () => {
    try {
      this.setState({ myInfo: false });
    } catch (err) {
      this.errorLog("handleClose ", err, "");
    }
  };

  metalNessChange = (newValue) => {
    try {
      let selectedMeshName = this.state.selectedMeshName;
      theModel.traverse((node) => {
        if (
          node.name === selectedMeshName &&
          node.material != undefined &&
          node.material.metalness != newValue
        ) {
          node.material.metalness = newValue;
        }
      });
    } catch (err) {
      this.errorLog("metalNessChange ", err, "");
    }
  };

  roughnessChange = (newValue) => {
    try {
      let selectedMeshName = this.state.selectedMeshName;
      theModel.traverse((node) => {
        if (
          node.name === selectedMeshName &&
          node.material != undefined &&
          node.material.roughness != newValue
        ) {
          // var material = new THREE.MeshStandardMaterial({
          //     roughness: newValue,
          //     metalness: node.material.metalness,
          //     map: node.material.map,
          //     color: node.material.color,
          // });
          // node.material = material
          node.material.roughness = newValue;
        }
      });
    } catch (err) {
      this.errorLog("roughnessChange ", err, "");
    }
  };

  checkGlassEffect = () => {
    try {
      if (isSetDiamond) {
        isSetDiamond = false;
        console.log("this.state.modelData",this.state.modelData)
        this.state.modelData.map((obj) => {
          if (theModel) {
            theModel.traverse((node) => {
              obj.customizer.texture.map((obj1) => {
                if (obj1.partName) {
                  if (
                    node.name.split("_")[0] === obj1.partName.split("_")[0] &&
                    obj1.glassEffect &&
                    node.material != undefined
                  ) {
                    // node.material.emissive = 0;
                    // node.material.roughness = 1;
                    // node.material.metalness = 0;
                    // node.material.reflectivity = 0.5;
                    // node.material.clearcoat = 0;
                    // node.material.clearcoatRoughness = 0;
                    // node.material.wireframeLinewidth = 1;
                    // node.material.fog = true;
                    // var newColorblack = new THREE.Color(0x000000);
                    // node.material.color = newColorblack
                    // node.material.roughness = 0.8;
                    // const gemBackMaterial = new THREE.MeshPhysicalMaterial({
                    //     clipBias: 0.003, textureWidth: 512, textureHeight: 512, color: 0xdddddd
                    // });
                    // node.material = gemBackMaterial
                    // const chromeMaterial = new THREE.MeshLambertMaterial({ color: 0xffffff, envMap: cubeRenderTarget.texture });
                    //  node.material = chromeMaterial
                  }
                  if (
                    node.name.split("_")[0] === obj1.partName.split("_")[0] &&
                    !obj1.isVisible &&
                    node.material != undefined
                  ) {
                    node.visible = false;
                  }

                  if (
                    node.name.split("_")[0] === obj1.partName.split("_")[0] &&
                    obj1.isDiamond &&
                    node.material != undefined
                  ) {
                    node.material.envMap = hdrCubeRenderTarget;
                    node.material.metalness = 1.0;
                    node.material.roughness = 0.0;
                    // node.material.reflectivity = 1.0;
                  }
                }
              });
            });
          }
        });
      }
    } catch (err) {
      this.errorLog("checkGlassEffect ", err, "");
    }
  };

  isHemisphereLightChange = (isChecked, skycolor, strength) => {
    try {
      let skyColor = "0xffffff";
      if (skycolor != undefined && skycolor != "") {
        skyColor = new THREE.Color(skycolor);
      }

      if (strength == -1) {
        strength = 0.61;
      }

      // Light 1 (HemisphereLight)
      // Add lights

      scene.children = scene.children.filter((obj, index) => {
        return obj.type != "HemisphereLight";
      });
      if (isChecked) {
        var hemiLight = new THREE.HemisphereLight(skyColor, skyColor, strength);
        hemiLight.position.set(0, 50, 0);
        scene.add(hemiLight);
      }

      if (this.state.isHemisphereLight != isChecked) {
        this.setState({ isHemisphereLight: isChecked });
      }
    } catch (err) {
      this.errorLog("isHemisphereLightChange ", err, "");
    }
  };

  isDirectionalLightChange = (isChecked, skycolor, strength) => {
    try {
      let scene = document.getElementById("scene");
      scene.children = scene.children.filter((obj) => {
        return obj.type != "DirectionalLight";
      });

      let skyColor = "0xffffff";
      if (skycolor != undefined && skycolor != "") {
        skyColor = new THREE.Color(skycolor);
      }

      if (strength == -1) {
        strength = 0.54;
      }
      // Light 2 (DirectionalLight)

      if (isChecked) {
        var dirLight = new THREE.DirectionalLight(skyColor, strength);
        dirLight.position.set(-8, 12, 8);
        dirLight.castShadow = true;
        dirLight.shadow.mapSize = new THREE.Vector2(1024, 1024);
        // Add directional Light to scene
        scene.add(dirLight);
      }

      if (this.state.isDirectionalLight != isChecked) {
        this.setState({ isDirectionalLight: isChecked });
      }
    } catch (err) {
      this.errorLog("isDirectionalLightChange ", err, "");
    }
  };

  isAmbientLightChange = (isChecked, skycolor) => {
    try {
      // Light 3 (AmbientLight)
      // let scene = document.getElementById('scene')
      scene.children = scene.children.filter((obj) => {
        return obj.type != "AmbientLight";
      });
      let skyColor = "0x404040";
      if (skycolor != undefined && skycolor != "") {
        skyColor = new THREE.Color(skycolor);
      }
      if (isChecked) {
        const ambientLight = new THREE.AmbientLight(skyColor);
        scene.add(ambientLight);
      }

      if (this.state.isAmbientLight != isChecked) {
        this.setState({ isAmbientLight: isChecked });
      }
    } catch (err) {
      this.errorLog("isAmbientLightChange ", err, "");
    }
  };

  isSpotLightChange = (isChecked, skycolor) => {
    try {
      // Light 4 (SpotLight)
      // let scene = document.getElementById('scene')

      scene.children = scene.children.filter((obj) => {
        return obj.type != "SpotLight";
      });

      let skyColor = "0xffffff";
      if (skycolor != undefined && skycolor != "") {
        skyColor = new THREE.Color(skycolor);
      }

      if (isChecked) {
        const spotLight = new THREE.SpotLight(skyColor);
        spotLight.position.set(10, 10, 10);
        scene.add(spotLight);
      }

      // const spotLightHelper = new THREE.SpotLightHelper(spotLight);
      // scene.object3D.add(spotLightHelper);

      if (this.state.isSpotLight != isChecked) {
        this.setState({ isSpotLight: isChecked });
      }
    } catch (err) {
      this.errorLog("isSpotLightChange", err, "");
    }
  };

  isPointLightChange = (isChecked, skycolor) => {
    try {
      // Light 5 (PointLight)
      // let scene = document.getElementById('scene')
      scene.children = scene.children.filter((obj) => {
        return obj.type != "PointLight";
      });

      let skyColor = "0xffffff";
      if (skycolor != undefined) {
        skyColor = new THREE.Color(skycolor);
      }

      if (isChecked) {
        const pointLight = new THREE.PointLight(skyColor, 1, 100);
        pointLight.position.set(10, 10, 10);
        scene.add(pointLight);
      }

      if (this.state.isPointLight != isChecked) {
        this.setState({ isPointLight: isChecked });
      }
    } catch (err) {
      this.errorLog("isPointLightChange", err, "");
    }
  };

  isPlatinumChange = (newValue) => {
    try {
      let color = new THREE.Color(newValue);
      var material = new THREE.MeshStandardMaterial({
        metalness: 1, // between 0 and 1
        roughness: 0.01, // between 0 and 1
        envMap: hdrCubeRenderTarget,
        color: color,
      });
      let selectedMeshName = this.state.selectedMeshName;
      theModel.traverse((node) => {
        if (node.name === selectedMeshName && node.material != undefined) {
          node.material = material;
        }
      });
    } catch (err) {
      this.errorLog("isPlatinumChange", err, "");
    }
  };

  isSilverChange = (newValue) => {
    try {
      let color = new THREE.Color(newValue);
      var material = new THREE.MeshStandardMaterial({
        metalness: 1, // between 0 and 1
        roughness: 0.2, // between 0 an
        color: color,
        envMap: hdrCubeRenderTarget,
      });

      let selectedMeshName = this.state.selectedMeshName;
      theModel.traverse((node) => {
        if (node.name === selectedMeshName && node.material != undefined) {
          node.material = material;
        }
      });
    } catch (err) {
      this.errorLog("isSilverChange", err, "");
    }
  };

  isRosegoldChange = (newValue) => {
    try {
      let color = new THREE.Color(newValue);
      var material = new THREE.MeshStandardMaterial({
        metalness: 1, // between 0 and 1
        roughness: 0.05, // between 0 and 1
        envMap: hdrCubeRenderTarget,
        color: color,
      });
      let selectedMeshName = this.state.selectedMeshName;
      theModel.traverse((node) => {
        if (node.name === selectedMeshName && node.material != undefined) {
          node.material = material;
        }
      });
    } catch (err) {
      this.errorLog("isRosegoldChange", err, "");
    }
  };

  isGoldChange = (newValue) => {
    try {
      let color = new THREE.Color(newValue);
      var material = new THREE.MeshStandardMaterial({
        metalness: 1, // between 0 and 1
        roughness: 0.05, // between 0 and 1
        // envMap: hdrCubeRenderTarget,
        color: color,
      });
      let selectedMeshName = this.state.selectedMeshName;
      theModel.traverse((node) => {
        if (node.name === selectedMeshName && node.material != undefined) {
          node.material = material;
        }
      });
    } catch (err) {
      this.errorLog("isGoldChange", err, "");
    }
  };

  isVisibleChange = () => {
    try {
      let selectedMeshName = this.state.selectedMeshName;
      theModel.traverse((node) => {
        if (node.name === selectedMeshName && node.material != undefined) {
          node.visible = !node.visible;
        }
      });
    } catch (err) {
      this.errorLog("isVisibleChange", err, "");
    }
  };

  closeMenu = () => {
    try {
      document.getElementById("Menu_panel").classList.add("menumove");
      document.getElementById("closetooltip").classList.add("ishidden");
      document.getElementById("close-menu").classList.add("ishidden");
      document.getElementById("opentooltip").classList.remove("ishidden");
      document.getElementById("open-menu").classList.remove("ishidden");

      if (document.getElementById("showLigts")) {
        document.getElementById("showLigts").classList.remove("lefttoright");
        document.getElementById("showLigts").classList.add("righttoleft");
      }

      if (document.getElementById("openexterior")) {
        document.getElementById("openexterior").classList.add("lefttoright");
        document.getElementById("openexterior").classList.remove("righttoleft");
      }
    } catch (err) {
      this.errorLog("closeMenu", err, "");
    }
  };

  openMenu = () => {
    try {
      document.getElementById("Menu_panel").classList.remove("menumove");
      document.getElementById("closetooltip").classList.remove("ishidden");
      document.getElementById("close-menu").classList.remove("ishidden");
      document.getElementById("opentooltip").classList.add("ishidden");
      document.getElementById("open-menu").classList.add("ishidden");

      if (document.getElementById("showLigts")) {
        document.getElementById("showLigts").classList.add("lefttoright");
        document.getElementById("showLigts").classList.remove("righttoleft");
      }

      if (document.getElementById("openexterior")) {
        document.getElementById("openexterior").classList.remove("lefttoright");
        document.getElementById("openexterior").classList.add("righttoleft");
      }
    } catch (err) {
      this.errorLog("openMenu", err, "");
    }
  };

  clippingPlane = () => {
    // renderer = new THREE.WebGLRenderer()
    renderer.localClippingEnabled = true;
    //   renderer.autoClear = false;
    let comp = this;
    comp.scene = theModel;
    comp.clipPlane = [
      new THREE.Plane(new THREE.Vector3(0, -90, 0), 0),
      new THREE.Plane(new THREE.Vector3(-90, 0, 0), 0),
        // new THREE.Plane(new THREE.Vector3(0, 0, -90), 0),
    ];
    let helpers = new THREE.Group();
    helpers.name = "Helpers";
    helpers.add(new THREE.PlaneHelper(comp.clipPlane[0], 100, 0xff0000));
    helpers.add(new THREE.PlaneHelper(comp.clipPlane[1], 100, 0x0000ff));
    // helpers.add(new THREE.PlaneHelper(comp.clipPlane[2], 100, 0x00ff00));
    console.log("helpers", helpers,theModel);
    comp.scene.add(helpers);
    
    theModel.traverse(function (node) {
      if (node.isMesh) {
        console.log("material name", node.material.name);
        //  if (node.material.name === "material_0"){
        //   node.material.name = "viraj"
        console.log("nodes color", node.material, new THREE.Color(0xffffff));
        node.material.clipIntersection = true;
        node.material.clippingPlanes = comp.clipPlane;
        node.material.color = new THREE.Color(0xffffff);
        //  node.material.clippingPlanes.set(comp.clipPlane);
        //  node.material.color.set(new THREE.Color(0xffffff));
        console.log(
          "node material",
          node.material.clippingPlanes,
          node.material.color,
          node.material.name
        );
      }
    });
    this.clipPlane[0].constant = 0.009;
    this.clipPlane[1].constant = 0;
    // this.clipPlane[2].constant = -0.001;
  };

  isSectioning = () => {
    // let modelObj = document.getElementById("gltfmodels634a54939fa416001237fb8d");
    this.setState({ isSectioning: true });
  };
  render() {  
    return (
      <div>
        {/* image  */}
        {/* {this.state.isSectioning && <Sectioning mesh={theModel} />} */}
        <div>
          <img src={logoimg} className="logoposition" />
        </div>
        {/* <!-- image end --> */}
        {/* <button
          style={{
            position: "absolute",
            zIndex: "1",
            right: "50px",
            top: "10px",
          }}
          onClick={this.clippingPlane}
        >
          Sectioning
        </button> */}
        {/* <!-- header section start --> */}

        <div className="menu-position menumove" id="Menu_panel">
          <div className="tooltip1">
            <span
              className="tooltiptext ishidden"
              style={{ width: "80px", marginLeft: "-40px" }}
              id="closetooltip"
            >
              Close Menu
            </span>
            <span
              className="tooltiptext"
              style={{ width: "80px", marginLeft: "-40px" }}
              id="opentooltip"
            >
              Open Menu
            </span>

            <div
              className="iconbg top ishidden"
              onClick={() => this.closeMenu()}
              id="close-menu"
            >
              <img src={closeimg} className="iconwidth " alt="close menu" />
            </div>
            <div
              className="iconbg top"
              onClick={() => this.openMenu()}
              id="open-menu"
            >
              <img src={menuimg} className="iconwidth " alt="open menu" />
            </div>
          </div>
        </div>
        {/* <!-- header section end --> */}
        {/* <!-- menu section  start--> */}
        {/* <div className="menu-automobile " id="Menu-automobile">
          <ul className="menuitemflex">
            <li>
              <img src={closeimg} alt="close" id="closebtn" />
            </li>
            <li id="customizerbox">
              {(this.state.isHemisphereLightShow ||
                this.state.isAmbientLightShow ||
                this.state.isSpotLightShow ||
                this.state.isPointLightShow) && (
                <button
                  className="commonbtn commonbtnblack"
                  id="lightdbtn"
                  onClick={() => {
                    $("#showLigts").toggleClass("lefttoright");
                    $("#showLigts").toggleClass("righttoleft");

                    $("#openexterior").removeClass("lefttoright");
                    $("#openexterior").addClass("righttoleft");
                    this.setState({
                      showLights: true,
                    });
                  }}
                >
                  Lights
                </button>
              )}
            </li>
          </ul>
        </div> */}
        {/* <!-- menu section  end--> */}
        <div className="positionbtn toptobottom" id="Positionbtn"></div>
        {/* <LeftMenu
          isDirectionalLightShow={this.state.isDirectionalLightShow}
          isHemisphereLightShow={this.state.isHemisphereLightShow}
          isAmbientLightShow={this.state.isAmbientLightShow}
          isSpotLightShow={this.state.isSpotLightShow}
          isPointLightShow={this.state.isPointLightShow}
          isHemisphereLight={this.state.isHemisphereLight}
          isDirectionalLight={this.state.isDirectionalLight}
          isAmbientLight={this.state.isAmbientLight}
          isSpotLight={this.state.isSpotLight}
          isPointLight={this.state.isPointLight}
          isHemisphereLightChange={this.isHemisphereLightChange}
          isDirectionalLightChange={this.isDirectionalLightChange}
          isAmbientLightChange={this.isAmbientLightChange}
          isSpotLightChange={this.isSpotLightChange}
          isPointLightChange={this.isPointLightChange}
        /> */}

        {this.state.showExterior && this.state.selectedMeshName != "" && (
          <SecoudMenu
            meshName={this.state.selectedMeshName}
            colores={this.state.colorCodes}
            images={this.state.images}
            partName={this.state.selectedMeshName}
            isVisible={this.state.isVisible}
            isMetal={this.state.isMetal}
            isStone={this.state.isStone}
            isDiamond={this.state.isDiamond}
            colorChange={this.colorChange}
            imageChange={this.imageChange}
            roughnessChange={this.roughnessChange}
            metalNessChange={this.metalNessChange}
            isVisibleChange={this.isVisibleChange}
          />
        )}
        {/* {this.state.isMetal && (
          <BottomMenu
            metalType={this.state.metalType}
            isPlatinumChange={this.isPlatinumChange}
            isSilverChange={this.isSilverChange}
            isRosegoldChange={this.isRosegoldChange}
            isGoldChange={this.isGoldChange}
          />
        )} */}

        <button
          style={{ margin: "auto 10px auto 0px" }}
          className="viewsclass"
          onClick={() => {
            this.handleInfo();
          }}
          title="Click to start tour again"
        >
          <HelpIcon style={{ color: "#000000" }}></HelpIcon>
        </button>

        <Dialog
          open={this.state.myInfo}
          onClose={() => {
            this.handleClose();
          }}
          aria-labelledby="form-dialog-title"
        >
          <video
            src={Vidoes}
            style={{ height: "315", width: "560" }}
            autoPlay="true"
            loop
          />
        </Dialog>
        <div id="container"></div>
      </div>
    );
  }
}
