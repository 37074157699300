import * as THREE from 'three';

export class Marker {
    constructor(intersection, radius) {
        this.intersection = null;
        this.markerObject = new THREE.Object3D();
        let material = CreateMaterial();
        let circleCurve = new THREE.EllipseCurve(0.0, 0.0, radius, radius, 0.0, 2.0 * Math.PI, false, 0.0);
        this.markerObject.add(CreateLineFromPoints(circleCurve.getPoints(50), material));
        this.markerObject.add(CreateLineFromPoints([new THREE.Vector3(-radius, 0.0, 0.0), new THREE.Vector3(radius, 0.0, 0.0)], material));
        this.markerObject.add(CreateLineFromPoints([new THREE.Vector3(0.0, -radius, 0.0), new THREE.Vector3(0.0, radius, 0.0)], material));
        this.UpdatePosition(intersection);
    }

    UpdatePosition(intersection) {
        // console.log("UpdatePosition")
        this.intersection = intersection
        let faceNormal = GetFaceWorldNormal(this.intersection);
        this.markerObject.updateMatrixWorld(true);
        this.markerObject.position.set(0.0, 0.0, 0.0);
        this.markerObject.lookAt(faceNormal);
        this.markerObject.position.set(this.intersection.point.x, this.intersection.point.y, this.intersection.point.z);
    }

    Show(show) {
        this.markerObject.visible = show;
    }

    GetIntersection() {
        return this.intersection;
    }

    GetObject() {
        return this.markerObject;
    }
}

export function CreateLineFromPoints(points, material) {
    console.log('create line');
    let geometry = new THREE.BufferGeometry().setFromPoints(points);
    return new THREE.Line(geometry, material);
}

function CreateMaterial() {
    return new THREE.LineBasicMaterial({
        color: 0x263238,
        depthTest: false
    });
}

function GetFaceWorldNormal(intersection) {
    let normalMatrix = new THREE.Matrix4();
        // console.log("intersection.object",intersection)
        // intersection.object.updateWorldMatrix(true, false);
        // console.log("normalMatrix intersection",intersection)
        normalMatrix.extractRotation(intersection.object.matrixWorld);
        let faceNormal = intersection.face.normal.clone();
        faceNormal.applyMatrix4(normalMatrix);
        return faceNormal;
}

export class ViewerExtraGeometry
{
    constructor (scene)
    {
        this.scene = scene;
        this.mainObject = null;
    }

    AddObject (object)
    {
        if (this.mainObject === null) {
            this.mainObject = new THREE.Object3D ();
            this.scene.add (this.mainObject);
        }
        this.mainObject.add (object);
    }

    Clear ()
    {
        if (this.mainObject === null) {
            return;
        }
        this.mainObject.traverse ((obj) => {
            if (obj.isMesh || obj.isLineSegments) {
                obj.geometry.dispose ();
            }
        });
        this.scene.remove (this.mainObject);
        this.mainObject = null;
    }
}

