/* eslint-disable */
import React from "react";
import "./MenuPanel.css";
import ColorLensIcon from "@material-ui/icons/ColorLens";
import TextureIcon from "@material-ui/icons/Texture";
import AllOutIcon from "@material-ui/icons/AllOut";
import AmpStoriesIcon from "@material-ui/icons/AmpStories";
import StarHalfIcon from "@material-ui/icons/StarHalf";
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Slider,
  Typography,
  withStyles,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { useState } from "react";
import { useEffect } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import SquareFootIcon from '@material-ui/icons/SquareFoot';
import ColorPicker from 'react-color-picker'
import 'react-color-picker/index.css'
import { AlphaPicker, BlockPicker, ChromePicker, SketchPicker } from "react-color";

const PrettoSlider = withStyles({
  root: {
    color: "#39d4bc",
    height: 8,
    padding: "10px 0px",
  },
  thumb: {
    height: 18,
    width: 18,
    //   backgroundColor: '#39d4bc',
    backgroundColor: "#39d4bc",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% - 0px)",
    top: "-27px",
    fontWeight: 600,
    "& .PrivateValueLabel-circle-11": {
      height: 25,
      width: 25,
    },
  },
  track: {
    height: 4,
    borderRadius: 4,
  },
  rail: {
    height: 4,
    borderRadius: 4,
  },
})(Slider);

const CustomTypography = withStyles({
  root: {
    color: "white",
    fontSize: "16px",
    fontWeight: "bolder",
  },
})(Typography);

const GreenCheckbox = withStyles({
  root: {
    color: "#39d4bc",
    "&$checked": {
      color: "#39d4bc",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function MenuPanel(props) {
  const { xChange, yChange, zChange, planeHelper, clipping, disableClipping, rendering, images, imageChange, metalNessChange, roughnessChange, onMeasurementBtnClick, onScreenshotBtnClick, linearDistance,onAngleMeasure, angle, colorChange,colors,backgroundColorChange } = props;
  const [value, setValue] = useState(30);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [xPlane, setXPlane] = useState(0);
  const [sectioningOpen, setSectioningOpen] = useState(false);
  const [metalnessOpen, setMetalnessOpen] = useState(false);
  const [roughnessOpen, setRoughnessOpen] = useState(false);
  const [textureOpen, setTextureOpen] = useState(false);
  const [colorOpen, setColorOpen] = useState(false);
  const [backgroundColorOpen, setBackgroundColorOpen] = useState(false);
  const [measureOpen, setMeasureOpen] = useState(false);
  const [isMeasurementStart, setIsMeasurementStart] = useState(false);
  const [isLinear,setIsLinear]=useState(false)
  const [isAngle,setIsAngle]=useState(false)
  const [colorPicker,setColorPicker]=useState('red')
  const [colorPickerBg,setColorPickerBg]=useState()

  // useEffect(() => {
  //   setLinearD(linearDistance)
  // },[linearD]);

  const menuEnable = () => {
    setIsMenuOpen(true);
  };

  const isSectining = () => {
    setIsMenuOpen(true);
    setSectioningOpen(true);
    setMetalnessOpen(false);
    setRoughnessOpen(false)
    setTextureOpen(false)
    setMeasureOpen(false);
    setColorOpen(false)
    setBackgroundColorOpen(false)
    clipping();
    rendering();
  };

  const isMetalness = () => {
    setIsMenuOpen(true);
    setSectioningOpen(false);
    setMetalnessOpen(true);
    setRoughnessOpen(false)
    setTextureOpen(false)
    setMeasureOpen(false);
    setColorOpen(false)
    setBackgroundColorOpen(false)
    disableClipping()
  };

  const isRoughness = () => {
    setIsMenuOpen(true);
    setSectioningOpen(false);
    setMetalnessOpen(false);
    setRoughnessOpen(true)
    setTextureOpen(false)
    setMeasureOpen(false);
    setColorOpen(false)
    setBackgroundColorOpen(false)
    disableClipping()
  }

  const isTexture = () => {
    setIsMenuOpen(true);
    setSectioningOpen(false);
    setMetalnessOpen(false);
    setRoughnessOpen(false)
    setTextureOpen(true)
    setMeasureOpen(false);
    setColorOpen(false)
    setBackgroundColorOpen(false)
    disableClipping()
  }
  const textureChange = (obj) => {
    console.log("texture obj",obj)
    imageChange(obj)
    rendering();
  }

  const isColor=()=>{
    console.log("colorChangeMenu")
    setColorOpen(true)
    setIsMenuOpen(true);
    setSectioningOpen(false);
    setMetalnessOpen(false);
    setRoughnessOpen(false)
    setTextureOpen(false)
    setMeasureOpen(false);
    setBackgroundColorOpen(false)
    disableClipping()
  }

  const isBackgroundChange=()=>{
    setIsMenuOpen(true);
    setBackgroundColorOpen(true)
    setColorOpen(false)
    setSectioningOpen(false);
    setMetalnessOpen(false);
    setRoughnessOpen(false)
    setTextureOpen(false)
    setMeasureOpen(false);
    disableClipping()
  }

  const colorChangeMenu=(color)=>{
    console.log("colorChangeMenu",color)
    colorChange(color)
    rendering();
  }

  const backgroundChange=(color)=>{
    console.log('color', color)
    setColorPickerBg(color)
    backgroundColorChange(color)
    rendering();
  }

  // const menuDisable = () => {
  //   setIsMenuOpen(false);
  // };

  const onsliderChangeX = (e, newValue) => {
    // console.log("newValue", newValue);
    xChange(newValue);
  };
  const onsliderChangeY = (e, newValue) => {
    // console.log("newValue", newValue);
    yChange(newValue);
  };
  const onsliderChangeZ = (e, newValue) => {
    // console.log("newValue", newValue);
    zChange(newValue);
  };

  const onMetalnessChange = (e, newValue) => {
    metalNessChange(newValue)
  }

  const onRoughnessChange = (e, newValue) => {
    roughnessChange(newValue)
  }

  const measure = (e) => {
    e.preventDefault();
    setIsMenuOpen(true);
    setMeasureOpen(true);
    setSectioningOpen(false);
    setMetalnessOpen(false);
    setRoughnessOpen(false);
    setTextureOpen(false);
    setColorOpen(false)
    disableClipping()
    setIsMeasurementStart(!isMeasurementStart);
   
  };

  const onLinear=()=>{
    setIsLinear(true)
    setIsAngle(false)
    onMeasurementBtnClick(isMeasurementStart);
  }

  const onAngle=()=>{
    setIsAngle(true)
    setIsLinear(false)
    onAngleMeasure(true)
  }

  const screenshot = (e) => {
    e.preventDefault();
    onScreenshotBtnClick();
  };



  const onDrag=(color)=> {
    // e.preventDefault()
    console.log("set colorpicker",color)
    setColorPicker(color.hex)
    colorChangeMenu(color.hex)
    // let rect = e.target.getBoundingClientRect();
  }

  // useEffect(()=>{

  // },[linearDistance])

  return (
    <div>
      <div className="menupanel-container">
        <Tooltip title="Roughness" placement="bottom">
          <AllOutIcon className="icons" onClick={isRoughness} />
        </Tooltip>
        <Tooltip title="Texture" placement="bottom">
          <TextureIcon className="icons" onClick={isTexture} />
        </Tooltip>
        <Tooltip title="Color" placement="bottom">
          <ColorLensIcon className="icons" onClick={isColor} />
        </Tooltip>
        <Tooltip title="Metalness" placement="bottom">
          <AmpStoriesIcon className="icons" onClick={isMetalness} />
        </Tooltip>
        <Tooltip title="Sectional Tool" placement="bottom">
          <StarHalfIcon className="icons" onClick={isSectining} />
        </Tooltip>
        <Tooltip title="Measure" placement="bottom">
          <SquareFootIcon className="icons" onClick={measure} />
        </Tooltip>
        <Tooltip title="Take a screenshot" placement="bottom">
          <WallpaperIcon className="icons" onClick={screenshot} />
        </Tooltip>
        <Tooltip title="Background Color" placement="bottom">
          <FormatColorFillIcon className="icons" onClick={isBackgroundChange} />
        </Tooltip>
        {/* </IconButton> */}
      </div>
      {isMenuOpen && (
        <div className="menu-box">
          
          {sectioningOpen && (
            <div>
              <CustomTypography id="input-slider" gutterBottom>
                Sectioning Tool
              </CustomTypography>
              <Typography
                style={{ color: "white", fontWeight: "600", fontSize: "14px" }}
              >
                X-Axis:
              </Typography>
              <PrettoSlider
                onChange={onsliderChangeX}
                defaultValue={0}
                min={-5}
                max={5}
                step={0.1}
                valueLabelDisplay="auto"
                aria-label="pretto slider"
              />
              <Typography
                style={{ color: "white", fontWeight: "600", fontSize: "14px" }}
              >
                Y-Axis:
              </Typography>
              <PrettoSlider
                onChange={onsliderChangeY}
                defaultValue={0}
                min={-5}
                max={5}
                step={0.1}
                valueLabelDisplay="auto"
                aria-label="pretto slider"
              />
              <Typography
                style={{ color: "white", fontWeight: "600", fontSize: "14px" }}
              >
                Z-Axis:
              </Typography>
              <PrettoSlider
                onChange={onsliderChangeZ}
                defaultValue={0}
                min={-5}
                max={5}
                step={0.1}
                valueLabelDisplay="auto"
                aria-label="pretto slider"
              />
              <FormControlLabel
                control={
                  <GreenCheckbox
                    checked={planeHelper.visible}
                    onChange={planeHelper}
                  />
                }
                label={
                  <Typography variant="body1" style={{ fontWeight: 600 }}>
                    Enable Helper
                  </Typography>
                }
                style={{ color: "white" }}
              />
            </div>
          )}
          {metalnessOpen && (
            <div>
              <CustomTypography>Metalness:</CustomTypography>
              <PrettoSlider
                onChange={onMetalnessChange}
                defaultValue={0}
                min={0}
                max={1}
                step={0.1}
                valueLabelDisplay="auto"
                aria-label="pretto slider"
              />
            </div>
          )}
          {roughnessOpen && (
            <div>
              <CustomTypography>Roughness:</CustomTypography>
              <PrettoSlider
                onChange={onRoughnessChange}
                defaultValue={0}
                min={0}
                max={1}
                step={0.1}
                valueLabelDisplay="auto"
                aria-label="pretto slider"
              />
            </div>
          )}
          {textureOpen && (
            <div>
              <CustomTypography>Texture:</CustomTypography>
              {images.length > 0 ?
                <ul className="texture-menu">
                  {images.map((obj) => {
                    return (
                      <li onClick={() => textureChange(obj)}>
                        <img src={'https://d2mjqh1irji0ds.cloudfront.net/' + obj} width="40" height="40" style={{ borderRadius: '5px' }} />
                      </li>

                    )
                  })}
                </ul>
                : <p style={{ color: "white" }}>Texture is not available</p>}
            </div>
          )}
         
          {measureOpen && (
            <div>
              <CustomTypography>Measurement:</CustomTypography>
              <div className="measure-option">
                <button className="option-btn" onClick={onLinear}>Linear</button>
                <button className="option-btn" onClick={onAngle}>Angle</button>
              </div>
              <p style={{ color: "white" }}>Hold <b>CTRL</b> Key to Start the measurement.</p>
             {isLinear && <div>
              <p style={{color:"white"}}>Distance in Meter: <b>{linearDistance ? linearDistance && linearDistance.toFixed(2) : 0}</b> m</p>
              <p style={{color:"white"}}>Distance in CM: <b>{linearDistance ? linearDistance && (linearDistance.toFixed(2) * 100).toFixed(2): 0}</b> cm</p>
              <p style={{color:"white"}}>Distance in Inch: <b>{linearDistance ?linearDistance && (linearDistance.toFixed(2) * 39.37).toFixed(2): 0}</b> inch </p>
              <p style={{color:"white"}}>Distance in Feet: <b>{linearDistance ?linearDistance && (linearDistance.toFixed(2) * 3.281).toFixed(2): 0}</b> ft </p>
              </div>}
             {isAngle && <div>
              <p style={{color:"white"}}>Angle in Degree: <b>{angle ? angle && (180-angle).toFixed(2) : 0}</b> °</p>
              <p style={{color:"white"}}>Angle in Radian: <b>{angle ? angle && ((180-angle).toFixed(2) * 0.01745).toFixed(2): 0}</b> rad</p>
              </div>}
            </div>
          )}
           {colorOpen && (
            <div>
              <CustomTypography>Color:</CustomTypography>
              <ChromePicker styles={{margin:"15px 0px"}} width="200px" color={colorPicker} onChangeComplete={onDrag} />
              {console.log("colors",colors)}
              {colors.length > 0 ?
                <ul className="texture-menu">
                  {colors.map((col) => {
                    return (
                      <li onClick={() => colorChangeMenu(col)}>
                        <div className="color-pallet" style={{background:`${col}`}}></div>
                        {console.log(`background:${col}`)}
                      </li>

                    )
                  })}
                </ul>
                : <p style={{ color: "white" }}>Color is not available</p>}
            </div>
          )}
           {backgroundColorOpen && (
        <div>
           <CustomTypography>Background Color:</CustomTypography>
          <ChromePicker styles={{margin:"15px 0px"}} width="200px" color={colorPickerBg} onChangeComplete={backgroundChange} />
        </div>
      )}
        </div>
      )}
     
    </div>
  );
}
