/* eslint-disable */
import React, { Component } from 'react'
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import App from './App';
import Viewer from './Viewer';
// import Viewer1 from './Viewer1';
import Warning from './Component/Warning/Warning';
import Test from './Test2';

export default class RouterContainier extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {

    }
    render() {
        return (
            <div>
                <Router history={createBrowserHistory}>
                    <Switch>
                        {/* <Route exact path="/" component={App} /> */}
                        <Route exact path="/view/:projectid" component={Viewer} />
                        <Route exact path="/warning" component={Warning} />
                        <Route exact path="/test/:projectid" component={Test} />
                        {/* <Route exact path="/view1/:projectid" component={Viewer1} /> */}
                    </Switch>
                </Router>

            </div>
        )
    }
}