/* eslint-disable */
import React from "react";
import * as THREE from "three";
import { useEffect } from "react";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { OrbitControlsGizmo } from "./orbitcontrolsgizmo";
import { OrientationHelper } from "./orientationhelper";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import logoimg from "./automobile/udhyog-20210701125657.png";
import { useState } from "react";
import axios from "axios";
import MenuPanel from "./Component/MenuPanel";
import {
  CSS2DRenderer,
  CSS2DObject,
} from "three/examples/jsm/renderers/CSS2DRenderer";
import './Test.css'
import { CreateLineFromPoints, Marker, UpdatePosition, ViewerExtraGeometry } from "./measureTool";

let camera, scene, renderer, theModel, controls, controlsGizmo, orientationHelper;
let _lastCLick = Date.now();
let raycaster = new THREE.Raycaster();
let mouse = new THREE.Vector2();
let ctrlDown = false;
let ActrlDown = false;
let lineId = 0;
let intersectsM;
let line;
let drawingLine = false;
let labelRenderer;
let ssRenderer;
let finalAngle;
let markers = [];
var strDownloadMime = "image/octet-stream";
let mainObject;
let distance;
const pickableObjects = new Array();
const measurementLabels = {};
let helpers;
let tempMarker = null;


const clipPlanes = [
  new THREE.Plane(new THREE.Vector3(-1, 0, 0), 0),
  new THREE.Plane(new THREE.Vector3(0, -1, 0), 0),
  new THREE.Plane(new THREE.Vector3(0, 0, -1), 0),
];

function Test() {
  const [modelData, setModelData] = useState([]);
  const [selectedMeshName, setSelectedMeshName] = useState();
  const [colorCodes, setColorCodes] = useState([]);
  const [images, setImages] = useState([]);
  const [linearEnable,setLinearEnable]=useState(true)

  useEffect(() => {
    let currentProjectId = window.location.href;
    currentProjectId = currentProjectId.substring(
      currentProjectId.lastIndexOf("/") + 1
    );

    // console.log("currentProjectId", currentProjectId);
    getProjectDetails(currentProjectId,true);
    return 
  }, []);



  // For Sectioning Tool //

  const onChangeX = (value) => {
    // console.log("value",value)
    clipPlanes[0].constant = value;
    if (camera) {
      renderer.render(scene, camera);
    }
  };

  const onChangeY = (value) => {
    // console.log("value",value)
    clipPlanes[1].constant = value;
    if (camera) {
      renderer.render(scene, camera);
    }
  };

  const onChangeZ = (value) => {
    // console.log("value",value)
    clipPlanes[2].constant = value;
    if (camera) {
      renderer.render(scene, camera);
    }
  };

  

  // Fetching model details // 

  const getProjectDetails = (projectId,bool) => {
    axios
      .get(`https://ptpvr.com/projects/${projectId}`, {
        headers: { "x-access-token": "null" },
      })
      .then((res) => {
        let project = res.data;

        axios
          .post("https://ptpvr.com/paidview/isviewvalid", {
            product: "Udyog",
            uid: project.uid,
          })
          .then((resview) => {
            if (resview.data.isAllowed) {
              axios
                .put(`https://ptpvr.com/projects/updateview/${project._id}`, {
                  views: project.views + 1,
                })
                .then((response) => {
                  // console.log("response",response)
                })
                .catch((error) => {
                  console.log("error", error);
                });

              // console.log(
              //   "res.data.scenes[0].gltfmodels",
              //   res.data.scenes[0].gltfmodels
              // );
              setModelData(res.data.scenes[0].gltfmodels);
              for (let i = 0; i < res.data.scenes[0].gltfmodels.length; i++) {
                console.log(
                  "infirst for :>> ",
                  res.data.scenes[0].gltfmodels[i].customizer.texture,
                  res.data.scenes[0].gltfmodels[i]
                );
                for (
                  let j = 0;
                  j <
                  res.data.scenes[0].gltfmodels[i].customizer.texture.length;
                  j++
                ) {
                  console.log(
                    "textureImage",
                    res.data.scenes[0].gltfmodels[i].customizer.texture[j]
                      .textureImage
                  );
                  setColorCodes(
                    res.data.scenes[0].gltfmodels[i].customizer.texture[0]
                      .textureColor
                  );
                  setImages(
                    res.data.scenes[0].gltfmodels[i].customizer.texture[0]
                      .textureImage
                  );
                }
              }
              init(res.data.scenes[0].gltfmodels[0],bool);
              animate();


            } else {
              window.location.href = "/warning";
            }
          })
          .catch((error) => {
            // this.errorLog("isviewvalid", err, "");
            console.log("error", error);
          });
      })
      .catch((err) => {
        console.log("err", err);
        // this.errorLog("getProjectDetails", err, "");
      });
  };


  // Load Model //

  const init = (modelData,bool) => {

    renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.localClippingEnabled = true;
    if(bool){
      document.body.appendChild(renderer.domElement);
    }

    scene = new THREE.Scene();
    scene.background = new THREE.Color(0xF5F5F5);
    // scene.background = new THREE.Color(0xffffff);
    camera = new THREE.PerspectiveCamera(
      70,
      window.innerWidth / window.innerHeight,
      0.1,
      100000
    );


    camera.position.set(-1.5, 2.5, 3.0);

    controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;

    // Obit Controls Gizmo
    controlsGizmo = new OrbitControlsGizmo(controls, { size: 100, padding: 8 });

    // orientation helper
    const ohOptions = {
      className: 'orientation-helper-in-scene'
    },
      ohLabels = {
        px: 'Right',
        nx: 'Left',
        pz: 'Front',
        nz: 'Back',
        py: 'Top',
        ny: 'Bottom'
      };



    // Add the Gizmo to the document
    if(bool){
      document.body.appendChild(controlsGizmo.domElement);
    }
    controls.target.set(0, 1, 0);
    controls.update();
    controls.addEventListener("change", render); 
    // use only if there is no animation loop
    // controls.minDistance = 1;
    // controls.maxDistance = 10;
    // controls.enablePan = false;
    const light = new THREE.HemisphereLight(0xffffff, 0x080808, 2.5);
    light.position.set(-1.25, 1, 1.25);
    light.castShadow=true
    scene.add(light);

    // const helper = new THREE.CameraHelper( light.shadow.camera );
    // scene.add( helper );

    const loader = new GLTFLoader();
    loader.load(
      "https://pptvr-3d-models.s3.ap-south-1.amazonaws.com/" + modelData.src,
      (gltf) => {
        console.log("gltf.scene", gltf.scene);
        theModel = gltf.scene;
        setModelData([theModel]);
        mainObject=gltf.scene
        //   theModel.rotation._x = obj.rotation.x;
        //   theModel.rotation._y = obj.rotation.y;
        //   theModel.rotation._z = obj.rotation.z;
        // gltf.scene.position.x = modelData.position.x;
        // gltf.scene.position.y = modelData.position.y;
        // gltf.scene.position.z = modelData.position.z;
        theModel.scale.y = 0.1;
        theModel.scale.z = 0.1;
        theModel.scale.x = 0.1;
        theModel.position.x=0
        theModel.position.y=0
        theModel.position.z=0
        scene.add(theModel);
        console.log("theModel", theModel);

        orientationHelper = new OrientationHelper(camera, controls, ohOptions, ohLabels);
        orientationHelper.addEventListener('change', onOrientationHelperChange);
        orientationHelper.addEventListener('click', onOrientationHelperClick);

        if(bool){
          document.body.appendChild(orientationHelper.domElement);
        }
        render();
      }
    );

    
    // const gridHelper = new THREE.GridHelper(500, 500, 0xff0000);
    // gridHelper.position.set(0, -4.5, 0);
    // scene.add(gridHelper);
    
    // X Y Z plane for sectioning tool

    helpers = new THREE.Group();
    helpers.add(new THREE.PlaneHelper(clipPlanes[0], 8, 0xff0000));
    helpers.add(new THREE.PlaneHelper(clipPlanes[1], 8, 0x00ff00));
    helpers.add(new THREE.PlaneHelper(clipPlanes[2], 8, 0x0000ff));
    helpers.visible = false;
    scene.add(helpers);

    window.addEventListener("resize", onWindowResize);
    renderer.domElement.addEventListener("click", onClick);
  };

  function animate() {
    requestAnimationFrame(animate);
    controls.update();
    // console.log("canvas renderer",renderer)
    // if (resizeRendererToDisplaySize(renderer)) {
    //   const canvas = renderer.domElement;
    //   camera.aspect = canvas.clientWidth / canvas.clientHeight;
    //   camera.updateProjectionMatrix();
    //   console.log("canvas renderer", canvas, renderer);
    // }

    // if (theModel != null && loaded == false) {
    //     initialRotation();
    // }
  }


  function onOrientationHelperChange(ev) {

    translateCamera(ev.direction);

  }

  function onOrientationHelperClick(ev) {

    // Simulate double-click (less than 250ms)
    let nClick = Date.now();
    // console.log("nClick - _lastCLick", parseInt(nClick - _lastCLick))
    translateCamera(ev.normal);
    _lastCLick = nClick;

  }

  function translateCamera(direction) {

    // console.log("direction", direction)
    controls.enabled = false;

    const dist = camera.position.distanceTo(controls.target),
      newCameraPos = controls.target.clone().add(direction.multiplyScalar(dist));

    camera.position.set(newCameraPos.x, newCameraPos.y, newCameraPos.z);

    controls.enabled = true;

  }

  //To Enable Sectioning tool

  const clippingEnable = () => {
    renderer.localClippingEnabled = true;
    theModel &&
      theModel.traverse(function (node) {
        if (node.isMesh) {
          node.material.clipIntersection = true;
          node.material.clippingPlanes = clipPlanes;
          node.material.color = new THREE.Color(0xffffff);
        }
      });
  };

  const clippingDisable = () => {
    // console.log("clipping disable")
    renderer.localClippingEnabled = false;
    render();
  };

  const helperEnable = (event) => {
    helpers.visible = event.target.checked;
    if (camera) {
      renderer.render(scene, camera);
    }
  };

  // To get x,y,z coordinates

  // const getMousePosition = (clientX, clientY) => {
  //   var mouse2D = new THREE.Vector3();
  //   var mouse3D = new THREE.Vector3();
  //   mouse2D.x = (clientX / window.innerWidth) * 2 - 1;
  //   mouse2D.y = -(clientY / window.innerHeight) * 2 + 1;
  //   mouse2D.z = 0.5;
  //   // mouse3D = mouse3D.unproject(mouse2D.clone(),camera);
  //   if (camera) {
  //     mouse3D = mouse2D.unproject(camera)
  //   }
  //   return mouse3D;
  // }

  const onClick = (event) => {
    // console.log("on click trigger")
    // console.log("mouse event",getMousePosition(event.clientX, event.clientY))
    if (camera) {

      raycaster.setFromCamera(mouse, camera);
      var intersects = raycaster.intersectObjects(scene.children, true);
      if (intersects[0] !== undefined) {
        console.log("selected mesh", intersects[0].object.name);
        setSelectedMeshName(intersects[0].object.name);
        // if (modelData !== [] && modelData.length > 0) {
        //   console.log("modelData", modelData);
        // }
          console.log("modelData",modelData)
            for (let i = 0; i < modelData.length; i++) {
            for (let j = 0; j < modelData[i].customizer.texture.length; j++) {
              console.log("color",modelData[i].customizer.texture[j].textureColor)
              setColorCodes(modelData[i].customizer.texture[j].textureColor)
            }
          }
      }
    }
    // render()
  };

  const onWindowResize = () => {
    // console.log("Resize");
    if (camera) {

      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();

      renderer.setSize(window.innerWidth, window.innerHeight);

      render();
    }
  };

  const render = () => {
    // console.log("Render");
    if (labelRenderer) {
      labelRenderer.render(scene, camera);
    }
    // renderer.clear();
    // renderer.clearDepth();
    // renderer.domElement.addEventListener("click", onClick);
    renderer.render(scene, camera);
  };

  const metalNessChange = (newValue) => {
    try {
      let meshName = selectedMeshName;
      theModel.traverse((node) => {
        if (
          node.name === meshName &&
          node.material !== undefined &&
          node.material.metalness !== newValue
        ) {
          node.material.metalness = newValue;
          if (camera) {
            renderer.render(scene, camera);
          }
        }
      });
    } catch (err) {
      this.errorLog("metalNessChange ", err, "");
    }
  };

  const roughnessChange = (newValue) => {
    try {
      let meshName = selectedMeshName;
      theModel.traverse((node) => {
        if (
          node.name === meshName &&
          node.material !== undefined &&
          node.material.roughness !== newValue
        ) {
          // var material = new THREE.MeshStandardMaterial({
          //     roughness: newValue,
          //     metalness: node.material.metalness,
          //     map: node.material.map,
          //     color: node.material.color,
          // });
          // node.material = material
          node.material.roughness = newValue;
          if (camera) {
            renderer.render(scene, camera);
          }
        }
      });
    } catch (err) {
      this.errorLog("roughnessChange ", err, "");
    }
  };

  const colorChange = (colorCode) => {
    try {
      console.log("colorCode , selectedmesh",colorCode,selectedMeshName)
      let meshName = selectedMeshName;
      let color = new THREE.Color(colorCode);
      theModel.traverse((node) => {
        if (node.name === meshName && node.material !== undefined) {
          node.material.color = color;
          console.log("in if")
        }
      });
      setTimeout(()=>{
        renderer.render(scene, camera);
      },500)
    } catch (err) {
      this.errorLog("colorChange ", err, "");
    }
  };

  const imageChange = (image) => {
    try {
      let meshName = selectedMeshName;
      console.log("selectedMeshName",selectedMeshName,image)
      theModel.traverse((node) => {
        if (node.name === meshName && node.material !== undefined) {
          let txt = new THREE.TextureLoader().load(
            "https://pptvr-images.s3.ap-south-1.amazonaws.com/" + image
          );
          // console.log("txt", txt);
          txt.wrapS = THREE.RepeatWrapping;
          txt.wrapT = THREE.RepeatWrapping;
          let new_mtl = new THREE.MeshPhongMaterial({
            map: txt,
            metalness: 0,
            roughness: 1,
          });
          node.material = new_mtl;
          setTimeout(()=>{
            renderer.render(scene, camera);
          },500)
          // render()
        }
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  // For measure tool //

  const measureEnable = () => {
    // console.log("measureEnable");
    // if(linearEnable){
    labelRenderer = new CSS2DRenderer();
    labelRenderer.setSize(window.innerWidth, window.innerHeight);
    labelRenderer.domElement.style.position = "absolute";
    labelRenderer.domElement.style.top = "0px";
    labelRenderer.domElement.style.pointerEvents = "none";
    document.body.appendChild(labelRenderer.domElement);
    theModel.traverse(function (child) {
      pickableObjects.push(child);
    });
    window.addEventListener("keydown", function (event) {
      if (event.key === "Control") {
        ctrlDown = true;
        controls.enabled = false;
        renderer.domElement.style.cursor = "crosshair";
        // console.log("keydown");
      }
    });
    window.addEventListener("keyup", function (event) {
      if (event.key === "Control") {
        ctrlDown = false;
        controls.enabled = true;
        renderer.domElement.style.cursor = "pointer";
        if (drawingLine) {
          //delete the last line because it wasn't committed
          scene.remove(line);
          scene.remove(measurementLabels[lineId]);
          drawingLine = false;
        }
        // console.log("keyup");
      }
    });
    ctrlDown=false
    // console.log("eventlistner")
    // console.log("renderer.domElement enable",renderer.domElement)
    renderer.domElement.addEventListener("pointerdown", pointerDown, { once : false });
    document.addEventListener("mousemove", onDocumentMouseMove);
  // }
  };

  const disableMeasurement=(event)=>{
    // setLinearEnable(false)
    let currentProjectId = window.location.href;
    currentProjectId = currentProjectId.substring(
      currentProjectId.lastIndexOf("/") + 1
    );
    getProjectDetails(currentProjectId,false);
    console.log("disableMeasurement")
    // window.removeEventListener("keydown",function (event) {
    //   if (event.key === "Control") {
    //     ctrlDown = true;
    //     controls.enabled = false;
    //     renderer.domElement.style.cursor = "crosshair";
    //     // console.log("keydown");
    //     console.log("disableMeasurement")
    //   }
    // })
    // window.removeEventListener("keyup",function (event) {
    //   if (event.key === "Control") {
    //     ctrlDown = false;
    //     controls.enabled = true;
    //     renderer.domElement.style.cursor = "pointer";
    //     if (drawingLine) {
    //       //delete the last line because it wasn't committed
    //       scene.remove(line);
    //       scene.remove(measurementLabels[lineId]);
    //       drawingLine = false;
    //     }
    //     // console.log("keyup");
    //   }
    // })
    // console.log("renderer.domElement disable",renderer.domElement )
    
      // document.getElementById("remove").removeEventListener("click",pointerDown)
    // console.log("renderer.domElement",renderer.domElement)
    // renderer.domElement.removeEventListener("pointerdown",pointerDown)
    // pointerDown(false)
    // let canvas=renderer.domElement
    // canvas.removeEventListener("pointerdown",pointerDown)
    // document.removeEventListener("mousemove",onDocumentMouseMove)
    scene.removeEventListener("mousemove",onDocumentMouseMove)

  }

  const pointerDown = (event) => {
    // console.log("disabled pointerdown")
    if (ctrlDown) {
      raycaster.setFromCamera(mouse, camera);
      intersectsM = raycaster.intersectObjects(pickableObjects, false);
      console.log("pointerDown", intersectsM);
      if (intersectsM.length > 0) {
        if (!drawingLine) {
          // console.log("Line start");
          //start the line
          const points = [];
          points.push(intersectsM[0].point);
          points.push(intersectsM[0].point.clone());
          // console.log("points",points)
          const geometry = new THREE.BufferGeometry().setFromPoints(points);
          // console.log("geometry",geometry)
          line = new THREE.LineSegments(
            geometry,
            new THREE.LineBasicMaterial({
              color: 0x000000,
              transparent: true,
              opacity: 0.75,
              // depthTest: false,
              // depthWrite: false
            })
          );

          line.frustumCulled = false;
          // console.log("line",line)
          scene.add(line);
          const measurementDiv = document.createElement("div");
          measurementDiv.className = "measurementLabel";
          measurementDiv.innerText = "0.0m";
          // console.log("measurementDiv", measurementDiv);
          const measurementLabel = new CSS2DObject(measurementDiv);
          measurementLabel.position.copy(intersectsM[0].point);
          measurementLabels[lineId] = measurementLabel;
          // console.log("measurementLabels :>> ", measurementLabels, lineId);
          scene.add(measurementLabels[lineId]);
          drawingLine = true;
          render();
        } else {
          //finish the line
          const positions = line.geometry.attributes.position.array;
          positions[3] = intersectsM[0].point.x;
          positions[4] = intersectsM[0].point.y;
          positions[5] = intersectsM[0].point.z;
          line.geometry.attributes.position.needsUpdate = true;
          lineId++;
          drawingLine = false;
        }
      }
    }
  };

  const onDocumentMouseMove = (event) => {
    // console.log("Mouse moving");
    event.preventDefault();
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    if (drawingLine) {
      raycaster.setFromCamera(mouse, camera);
      intersectsM = raycaster.intersectObjects(pickableObjects, false);
      if (intersectsM.length > 0) {
        const positions = line.geometry.attributes.position.array;
        const v0 = new THREE.Vector3(positions[0], positions[1], positions[2]);
        const v1 = new THREE.Vector3(
          intersectsM[0].point.x,
          intersectsM[0].point.y,
          intersectsM[0].point.z
        );

        positions[3] = intersectsM[0].point.x;
        positions[4] = intersectsM[0].point.y;
        positions[5] = intersectsM[0].point.z;
        line.geometry.attributes.position.needsUpdate = true;
        distance = v0.distanceTo(v1);
        measurementLabels[lineId].element.innerText = distance.toFixed(2) + "m";
        measurementLabels[lineId].position.lerpVectors(v0, v1, 0.5);
        // console.log("distance", distance, measurementLabels);
        render();
      }
    }
  };

  // For Screenshot//

  const saveAsImage = () => {
    var imgData;
    ssRenderer = new THREE.WebGLRenderer({
      preserveDrawingBuffer: true
    });
    ssRenderer.setSize(window.innerWidth, window.innerHeight);
    if (camera) {
      ssRenderer.render(scene, camera)
    }

    try {
      var strMime = "image/jpeg";
      imgData = ssRenderer.domElement.toDataURL(strMime);
      // console.log("imgData", imgData, ssRenderer.domElement);
      saveFile(imgData.replace(strMime, strDownloadMime), "test.jpg");

    } catch (e) {
      console.log(e);
      return;
    }
  };

  const saveFile = (strData, filename) => {
    var link = document.createElement("a");
    if (typeof link.download === "string") {
      document.body.appendChild(link); //Firefox requires the link to be in the body
      link.download = filename;
      link.href = strData;
      link.click();
      document.body.removeChild(link); //remove the link when done
    } else {
      location.replace(uri);
    }
  };

  const angleMeasurement = () => {
    window.addEventListener("keydown", function (event) {
      if (event.key === "Control") {
        // console.log("control")
        ActrlDown = true;
      }
    });
    window.addEventListener("keyup", function (event) {
      if (event.key === "Control") {
        ActrlDown = false;
      }
    });
    // console.log("angleMeasurement")
    theModel.traverse(function (child) {
      pickableObjects.push(child);
    });
    document.addEventListener("mousemove", mouseMove);
      renderer.domElement.addEventListener("pointerdown", mouseClick);
  }

  const mouseClick=()=>{
    if(ActrlDown){
      let intersection = GetMeshIntersectionUnderMouse({x:event.clientX,y:event.clientY},camera,renderer.domElement.clientWidth,renderer.domElement.clientHeight)
    if (intersection === null) {
      ClearMarkers();
      return;
    }
    // console.log("markers", markers)
    if (markers.length === 2) {
      ClearMarkers();
    }

    AddMarker(intersection);
    }
  }

  const ClearMarkers = () => {
    markers = [];
    tempMarker = null;
  }

  const AddMarker=()=>{
    let intersection = GetMeshIntersectionUnderMouse({x:event.clientX,y:event.clientY},camera,renderer.domElement.clientWidth,renderer.domElement.clientHeight)
        let marker = GenerateMarker (intersection);
        markers.push (marker);
        if (markers.length === 2) {
            let material = new THREE.LineBasicMaterial ({
              color : 0x263238,
              depthTest : false
          });
            let aPoint = markers[0].GetIntersection().point;
            let bPoint = markers[1].GetIntersection().point;
            AddExtraObject (CreateLineFromPoints ([aPoint, bPoint], material));
            let calcRes=CalculateMarkerValues(markers[0],markers[1])
            let RadDeg=57.29577951308232;
            finalAngle=calcRes.facesAngle*RadDeg
            // console.log("angle",calcRes.facesAngle*RadDeg)
        }
  }

  const mouseMove=(event)=>{
    // console.log('mouse move',pickableObjects );

        let intersection = GetMeshIntersectionUnderMouse({x:event.clientX,y:event.clientY},camera,renderer.domElement.clientWidth,renderer.domElement.clientHeight)
        if (intersection === null) {
            if (tempMarker !== null) {
                tempMarker.Show (false);
                render();
            }
            return;
        }
        // console.log("tempMarker",tempMarker)
        if (tempMarker===null) {
            tempMarker = GenerateMarker (intersection);
        }
        tempMarker.UpdatePosition(intersection);
        tempMarker.Show (true);
        render();
      return;
  }

  const GetMeshIntersectionUnderMouse=(mouseCoords, camera, width, height)=>{
    if (mainObject === null) {
      return null;
    }

    if (mouseCoords.x < 0.0 || mouseCoords.x > width || mouseCoords.y < 0.0 || mouseCoords.y > height) {
        return null;
    }

    let raycaster = new THREE.Raycaster ();
    let mousePos = new THREE.Vector2 ();
    mousePos.x = (mouseCoords.x / width) * 2 - 1;
    mousePos.y = -(mouseCoords.y / height) * 2 + 1;
    raycaster.setFromCamera (mousePos, camera);
    let iSectObjects = raycaster.intersectObject (mainObject, true);
    for (let i = 0; i < iSectObjects.length; i++) {
        let iSectObject = iSectObjects[i];
        if (iSectObject.object.type === 'Mesh' && iSectObject.object.visible) {
            return iSectObject;
        }
    }

    return null;
  }

  const GenerateMarker=(intersection)=>{
    // console.log('generate marker',intersection);

        let radius = 0.1;
          let marker = new Marker (intersection, radius);
        AddExtraObject(marker.GetObject());
        return marker;
  }

  const GetBoundingSphere = (needToProcess) => {
    let geometry = new THREE.BufferGeometry().setFromPoints(points);
    return geometry.GetBoundingSphere(needToProcess);
  } 

  const AddExtraObject = (object) => {
    let extraGeometry = new ViewerExtraGeometry(scene);
    extraGeometry.AddObject(object)
    render()
  }

  const CalculateMarkerValues=(aMarker, bMarker)=>{
    const aIntersection = aMarker.GetIntersection ();
    const bIntersection = bMarker.GetIntersection ();
    const BigEps = 0.0001;
    let result = {
        pointsDistance : null,
        parallelFacesDistance : null,
        facesAngle : null
    };
    const aNormal = GetFaceWorldNormal (aIntersection);
    const bNormal = GetFaceWorldNormal (bIntersection);
    result.pointsDistance = aIntersection.point.distanceTo (bIntersection.point);
    result.facesAngle = aNormal.angleTo (bNormal);
    if (IsEqualEps (result.facesAngle, 0.0, BigEps) || IsEqualEps (result.facesAngle, Math.PI, BigEps)) {
        let aPlane = new THREE.Plane ().setFromNormalAndCoplanarPoint (aNormal, aIntersection.point);
        result.parallelFacesDistance = Math.abs (aPlane.distanceToPoint (bIntersection.point));
    }
    return result;
  }

  const GetFaceWorldNormal=(intersection)=>{
    let normalMatrix = new THREE.Matrix4 ();
    intersection.object.updateWorldMatrix (true, false);
    normalMatrix.extractRotation (intersection.object.matrixWorld);
    let faceNormal = intersection.face.normal.clone ();
    faceNormal.applyMatrix4 (normalMatrix);
    return faceNormal;
  }

  const IsEqualEps=(a, b, eps)=>{
    return Math.abs (b - a) < eps;
  }
  
  const backgroundChange=(col)=>{
    // console.log(`0x${col.hex.split("#")[1]}`)
    scene.background = new THREE.Color(col.hex);
  }
  return (
    <div id="test">
      <div id='orientCubeWrapper'></div>
      <div>
        <img src={logoimg} className="logoposition" />
      </div>
      {/* <button
        style={{
          position: "absolute",
          zIndex: "1",
          right: "50px",
          top: "10px",
        }}
        id="remove"
        onClick={disableMeasurement}
      >
        Disable Measure
      </button> */}
      <MenuPanel
        rendering={render}
        clipping={clippingEnable}
        disableClipping={clippingDisable}
        planeHelper={helperEnable}
        xChange={onChangeX}
        yChange={onChangeY}
        zChange={onChangeZ}
        images={images}
        colors={colorCodes}
        backgroundColorChange={backgroundChange}
        imageChange={imageChange}
        colorChange={colorChange}
        metalNessChange={metalNessChange}
        roughnessChange={roughnessChange}
        onMeasurementBtnClick={measureEnable}
        onAngleMeasure={angleMeasurement}
        onScreenshotBtnClick={saveAsImage}
        linearDistance={distance && distance}
        angle={finalAngle && finalAngle}
      />
    </div>
  );
}
  
export default Test;
