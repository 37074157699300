import React, { Component } from 'react'
import './main.css';
// import arow from '../automobile/arow.png'
// import uparow from '../automobile/uparow.png'

export default class BottomMenu extends Component {

    isPlatinumChange = () => {
        this.props.isPlatinumChange('#E5E4E2')
    }
    isSilverChange = () => {
        this.props.isSilverChange('#DBDDE5')
    }
    isRosegoldChange = () => {
        this.props.isRosegoldChange('#B76E79')
    }
    isGoldChange = () => {
        this.props.isGoldChange('#F4D912')
    }

    render() {
        return (
            <div >
                {this.props.metalType &&

                    <div className="positionexteriorbtn lefttoright bottomposition" id='bottomMenu'>
                        {/* <div>
                            <img src={arow} />

                        </div> */}
                        <ul className="exteriorflex">
                            <li>
                                {/* <img src={uparow} onClick="nextProject(7)" /> */}
                            </li>
                            <ul className="exteriorflex" id="exteriorList">
                                <li>
                                    <button className="commonbtn marignrightzero" onClick={this.isPlatinumChange} id="projectname1" > Platinum </button>
                                </li>
                                <li>
                                    <button className="commonbtn marignrightzero" onClick={this.isSilverChange} id="projectname1" > Silver </button>
                                </li>

                                <li>
                                    <button className="commonbtn marignrightzero" onClick={this.isRosegoldChange} id="projectname1" > Rose gold </button>
                                </li>
                                <li>
                                    <button className="commonbtn marignrightzero" onClick={this.isGoldChange} id="projectname1"> Gold </button>
                                </li>


                            </ul>

                            {/* <li>
                                <img src={arow} />
                            </li> */}
                        </ul>
                    </div>


                    // <div>
                    //     {this.props.metalType.isPlatinum &&
                    //         <div onClick={this.isPlatinumChange}>isPlatinum</div>
                    //     }
                    //     {this.props.metalType.isSilver &&
                    //         <div onClick={this.isSilverChange}>isSilver</div>
                    //     }
                    //     {this.props.metalType.isRosegold &&
                    //         <div onClick={this.isRosegoldChange}>isRosegold</div>
                    //     }
                    //     {this.props.metalType.isGold &&
                    //         <div onClick={this.isGoldChange}>isGold</div>
                    //     }
                    // </div>
                }
            </div>
        )
    }
}
