/* eslint-disable */
import { Slider, Typography } from '@material-ui/core';
import React, { Component } from 'react'
import * as THREE from "three";
export default class Sectioning extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clipZ:0,
            clipY:0,
            clipX:0,
        }
    }

    componentDidMount(){
        console.log('this.props', this.props)
        // this.el.renderer.localClippingEnabled = true
        
        // let el = this.el;
        let comp = this; // component level scope. Variables in this object are accessible anywhere within component.
        comp.scene = this.props.mesh;  
        // console.log("comp.clipPlane",comp.clipPlane);
       
       // Clipping Planes
        comp.clipPlane = [ new THREE.Plane( new THREE.Vector3 (0, 0, -90), 1 ),new THREE.Plane( new THREE.Vector3 (0, -90, 0), 1 ),new THREE.Plane( new THREE.Vector3 (-90, 0, 0), 1 )];
        // comp.clipPlane = [ new THREE.Plane( new THREE.Vector3 (0, 0, -1), 1 )];
        // PlaneHelper used to visualize the clipping plane.
        let helpers = new THREE.Group();
        helpers.name = "Helpers";
        helpers.add( new THREE.PlaneHelper( comp.clipPlane[0], 100, 0xff0000 ) );
        helpers.add( new THREE.PlaneHelper( comp.clipPlane[1], 100, 0x0000ff ) );
        helpers.add( new THREE.PlaneHelper( comp.clipPlane[2], 100, 0x00ff00 ) );
        console.log("helpers",helpers);
        comp.scene.add(helpers); // uncomment this line to make the helpers visible.                  
        
        // Get all materials that have "Trunk" in their name, and add the clipping planes to them.
      //   el.addEventListener('model-loaded', function(ev){
          // let mesh = el.getObject3D('mesh'); 
        //   console.log("this.state.selectedModelId",this.state.selectedModelId)
        //   let modelObj = document.getElementById(this.state.selectedModelId)
          console.log('mesh',this.props.mesh)
           if (!this.props.mesh){return;}
           this.props.mesh.traverse(function(node){
             if (node.isMesh){
              console.log("material name",node.material.name)
              //  if (node.material.name === "material_0"){
              //   node.material.name = "viraj"
                console.log("nodes color",node.material,new THREE.Color(0xffffff));
                node.material.clipIntersection=true
                 node.material.clippingPlanes = comp.clipPlane;   
                 node.material.color= new THREE.Color(0xffffff);
                //  node.material.clippingPlanes.set(comp.clipPlane); 
                //  node.material.color.set(new THREE.Color(0xffffff));
                 console.log("node material",node.material.clippingPlanes,node.material.color, node.material.name)
               }
            //  }
          });
      //   });   
      // }
      // tick: function(){
          console.log("this.clipPlane[0]",this.clipPlane)
          // console.log("this.clipPlane[0]",this.clipPlane[0].constant)
          this.clipPlane[0].constant=this.state.clipX
          this.clipPlane[1].constant=this.state.clipY
          this.clipPlane[2].constant=this.state.clipZ

          console.log("this.state.clipX",this.state.clipX)
    }

    handleChangeX=(e,newValue)=>{
        // console.log('e.target.value :>> ', newValue);
        this.setState({clipX:newValue})
        this.clipPlane[0].constant=newValue
    }
    handleChangeY=(e,newValue)=>{
        console.log('e.target.value :>> ', newValue);
        this.setState({clipY:newValue})
        this.clipPlane[1].constant=newValue
    }
    handleChangeZ=(e,newValue)=>{
        // console.log('e.target.value :>> ', newValue);
        this.setState({clipZ:newValue})
        this.clipPlane[2].constant=newValue
    }
    reset=()=>{
      this.clipPlane[0].constant=0
          this.clipPlane[1].constant=0
          this.clipPlane[2].constant=0
          this.setState({clipX:0,clipY:0,clipZ:0})
    }
  render() {
    return (
      <div style={{position:"absolute",zIndex:5,top:"40px",right:"50px"}}>
        <h2>Sectioning</h2>
        <Typography>Z-Axis:</Typography>
        <Slider value={this.state.clipX} onChange={this.handleChangeX} min={-50} max={50} step={0.01} aria-labelledby="continuous-slider"/>
        <Typography>Y-Axis:</Typography>
        <Slider value={this.state.clipY} onChange={this.handleChangeY} min={-50} max={50} step={0.01} aria-labelledby="continuous-slider"/>
        <Typography>X-Axis:</Typography>
        <Slider value={this.state.clipZ} onChange={this.handleChangeZ} min={-50} max={50} step={0.01} aria-labelledby="continuous-slider"/>
        <button onClick={this.reset}>Reset</button>
        </div>
    )
  }
}